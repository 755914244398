import { Handlers } from "duck/agents/ClaimAnalytics/types";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { ASSOCIATED_SIGNAL_EVENTS_TAB_KEY } from "pages/constants";

import {
  MAX_WINDOW_SIZE,
  MIN_WINDOW_SIZE,
} from "features/ui/Filters/FilterTypes/RelatesFilter/constants";

const getGoToAssociatedSignalEventsTabTool = (
  changeTabHandler: Handlers["changeTab"],
  updateSignalEventOccurrencesFilterHandler: Handlers["updateSignalEventOccurrencesFilter"],
  updateSignalEventOccurrencesWindowSizeHandler: Handlers["updateSignalEventOccurrencesWindowSize"]
): DynamicStructuredTool => {
  const goToAssociatedSignalEventsTabSchema = z.object({
    signalEventOccurrencesFilter: z
      .string()
      .optional()
      .describe(
        "Filter string for `signalEventID` attribute to filter the associated signal events returned in this tab. Note the attribute should be `signalEventID`."
      ),
    windowSize: z
      .number()
      .optional()
      .describe(
        "The window size in days where the signal events occurred within Claim's Repair Date." +
          `The window size should be between ${MIN_WINDOW_SIZE} and ${MAX_WINDOW_SIZE}. Default value: 30.`
      ),
  });

  type GoToAssociatedSignalEventsTab = z.infer<
    typeof goToAssociatedSignalEventsTabSchema
  >;

  const goToAssociatedSignalEventsTab = ({
    signalEventOccurrencesFilter,
    windowSize,
  }: GoToAssociatedSignalEventsTab) => {
    console.debug(
      `Navigating to Associated Signal Events tab with signalEventOccurrencesFilter: ${signalEventOccurrencesFilter}, windowSize: ${windowSize}`
    );

    changeTabHandler(ASSOCIATED_SIGNAL_EVENTS_TAB_KEY);

    if (windowSize) {
      updateSignalEventOccurrencesWindowSizeHandler(windowSize);
    }

    if (signalEventOccurrencesFilter) {
      updateSignalEventOccurrencesFilterHandler(signalEventOccurrencesFilter);
    }

    return "success";
  };

  return tool(goToAssociatedSignalEventsTab, {
    name: "goToAssociatedSignalEventsTab",
    description:
      "Call this tool to queue a navigation to the Associated Signal Events tab action",
    schema: goToAssociatedSignalEventsTabSchema,
  });
};

export default getGoToAssociatedSignalEventsTabTool;
