import { Handlers, RequestParams } from "duck/agents/ClaimAnalytics/types";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { CLAIMS_TAB_KEY } from "pages/ClaimAnalytics/constants";
import { claimsActionIds, ClaimsActionIdType } from "pages/utils";

const actionDescriptions: Map<ClaimsActionIdType, string> = new Map([
  [
    "y",
    "The metric to be displayed on the y-axis of the chart. Default value: 'cumulativeIPTV'.",
  ],
]);

// TODO: Change the keys from yAxis to y and xAxis to x so we don't need this mapping.
// This will require coordination with the data science team.
// https://viaduct-ai.atlassian.net/browse/DUCK-145
const keyMap: Map<ClaimsActionIdType, string> = new Map([["y", "yAxis"]]);

const getGoToClaimsTabTool = (
  changeTabHandler: Handlers["changeTab"],
  setClaimsChartOptionsHandler: Handlers["setClaimsChartOptions"],
  claimsChartOptions: RequestParams["claimsChartOptions"]
): DynamicStructuredTool => {
  const keys = claimsActionIds.map(
    (actionId) => keyMap.get(actionId) ?? actionId
  );
  type SchemaDefinition = {
    [K in (typeof keys)[number]]: z.ZodOptional<
      z.ZodEnum<[string, ...string[]]>
    >;
  };

  const schemaDefinition = claimsActionIds.reduce((acc, actionId) => {
    const key = keyMap.get(actionId) ?? actionId;
    return {
      ...acc,
      [key]: z
        .enum(claimsChartOptions[actionId])
        .optional()
        .describe(actionDescriptions.get(actionId) ?? ""),
    };
  }, {} as SchemaDefinition);

  const goToClaimsTabSchema = z.object(schemaDefinition);

  type GoToClaimsTab = z.infer<typeof goToClaimsTabSchema>;

  const goToClaimsTab = ({ yAxis }: GoToClaimsTab): string => {
    console.debug("Navigating to Claims tab with yAxis: " + yAxis);
    changeTabHandler(CLAIMS_TAB_KEY);
    setClaimsChartOptionsHandler(yAxis);
    return "success";
  };

  return tool(goToClaimsTab, {
    name: "goToClaimsTab",
    description:
      "Call this tool to queue a navigation to the Claims tab action.",
    schema: goToClaimsTabSchema,
  });
};

export default getGoToClaimsTabTool;
