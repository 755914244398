import qs from "qs";
import { createPath } from "react-router";

import { TAB_QUERY_PARAM } from "shared/constants";

import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";

import { routes } from "services/routes";

export enum Reload {
  HARD = "hard",
  SOFT = "soft",
  NONE = "none",
}

const updatedQueryParams: Record<string, string> = {};
let reloadRequired = Reload.NONE;

type LocationInfo = {
  reloadRequired: Reload;
  path: string;
  url: string;
};

/**
 * Return the current location information, and then reset it.
 * The path is currently hard-coded to "/claim-analytics" because that is
 * the only page currently supported by the agent.
 * When the agent evolves to support additional pages, this function will
 * need to evolve to support additional pages.
 *
 * @param reset If true, then reset the updated query string parameters and
 * reloadRequired flag.
 * @returns The path and the url with the updated query string parameters,
 * and flags to indicate whether a hard or soft reload is required.
 * If both hard and soft are true then a hard reload is needed.
 */
export const deliverLocationInfo = (reset: boolean = true): LocationInfo => {
  const priorQueryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const newQueryParams = { ...priorQueryParams, ...updatedQueryParams };

  // When the agent supports multiple pages, consider using window.location.pathname
  // to preserve the current page.
  const path = createPath({
    pathname: routes.claimAnalytics,
    search: qs.stringify(newQueryParams, { arrayFormat: "indices" }),
    hash: window.location.hash,
  });

  const response = {
    reloadRequired,
    path,
    url: `${window.location.origin}${path}`,
  };

  if (reset) {
    for (const key in updatedQueryParams) {
      delete updatedQueryParams[key];
    }
    reloadRequired = Reload.NONE;
  }

  return response;
};

export const updateQueryStringParameter = (
  paramName: string,
  paramValue: string,
  reload: Reload = Reload.NONE
): void => {
  updatedQueryParams[paramName] = paramValue;

  if (reload === Reload.HARD) {
    reloadRequired = Reload.HARD;
  } else if (reload === Reload.SOFT && reloadRequired === Reload.NONE) {
    reloadRequired = Reload.SOFT;
  }

  console.log(
    `${new Date().getTime()} set query string parameter "${paramName}" to "${paramValue}"`
  );
};

export const logUnsupportedAction = (functionName: string, args: any[]) => {
  console.log(functionName, args, "\nThis is not yet supported. Soon.");
};

export const navigateToTab = (tabId: string) => {
  updateQueryStringParameter(TAB_QUERY_PARAM, tabId, Reload.SOFT);
};

export const updateFilter = (
  filterQueryString: string,
  elementName: string,
  queryStringParameterName: string
): void => {
  const filters = filterBuilderQueryToFilterBuilderState(filterQueryString);
  if (!filters) {
    throw new Error(
      `Invalid filter string for ${elementName}, please try again.`
    );
  }

  updateQueryStringParameter(
    queryStringParameterName,
    filterQueryString,
    Reload.HARD
  );
};
