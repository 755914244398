import { getSortFilter } from "shared/api/utils";
import { useFailureModes } from "shared/api/v0_failureModes/hooks";

import { formatFailureModeToTableSchema } from "pages/Vehicles/utils";

import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

/**
 * Since we do not have /attributes API for failure modes, we
 * are using the failure modes API to get the failure modes and
 * then build schemas manually.
 */

const useVehiclesFailureModesSchema = (
  skipRequest?: boolean
): SchemaEntry[] => {
  const { data: failureModesData } = useFailureModes({
    sort: getSortFilter({ status: "desc" }),
    filter: getFiltersQuery(
      filterStateToFilterGroupState({
        status: {
          operator: FilterOperator.NOT_IN,
          values: ["archived"],
        },
      })
    ),
    skipRequest: skipRequest,
  });

  if (skipRequest || !failureModesData || failureModesData.length === 0) {
    return [];
  }

  return failureModesData.reduce((acc, failureMode) => {
    return [...acc, ...formatFailureModeToTableSchema(failureMode, true)];
  }, [] as SchemaEntry[]);
};

export default useVehiclesFailureModesSchema;
