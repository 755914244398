import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";

import { CustomSignalEventDefinition } from "shared/api/customSignalEvents/api";
import {
  useListCustomSignalEventDefinitions,
  useListCustomSignalEventDefinitionsCount,
} from "shared/api/customSignalEvents/hooks";
import { getSortFilter } from "shared/api/utils";
import NewCTA from "shared/components/NewCTA";
import useCustomSignalEventDefinitionSchema from "shared/schemas/customSignalEventDefinitionsSchema";
import { SortBy } from "shared/types";

import APIError from "features/ui/APIError";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { OnSortParams } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import TableCount from "features/ui/Table/TableCount";
import Title from "features/ui/Title";

import { routes } from "services/routes";

import { CTA_TEXT, PAGE_TITLE } from "./constants";
import { getCustomSignalEventState } from "./utils";

const PAGE_KEY = "customSignalEvents";
const ROWS_PER_PAGE = 10;

const DEFAULT_SORT: SortBy = { name: "asc" };

const formatRow = (
  customSignalEventDefinition: CustomSignalEventDefinition
) => {
  const { ID, name } = customSignalEventDefinition;
  const pathName = generatePath(routes.customSignalEvent, { id: ID });

  const {
    actionsState: { canNavigateToDetailsPage },
  } = getCustomSignalEventState(customSignalEventDefinition.status);

  return {
    ...customSignalEventDefinition,
    name: canNavigateToDetailsPage ? (
      <Link to={pathName} className="text-metabase-blue hover:underline">
        {name}
      </Link>
    ) : (
      name
    ),
  };
};

const CustomSignalEvents = () => {
  const { schema } = useCustomSignalEventDefinitionSchema();

  const {
    manageOnFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListCustomSignalEventDefinitions({
      filter: getFiltersQuery(filters),
      sort: getSortFilter(sort),
      limit: ROWS_PER_PAGE,
    });

  const {
    data: countData,
    isLoading: countIsLoading,
    error: countError,
  } = useListCustomSignalEventDefinitionsCount({
    filter: getFiltersQuery(filters),
  });

  // re-format the data - but only when data changes
  const formattedData = useMemo(
    () => data?.map((data) => formatRow(data)),
    [data]
  );

  if (isLoading) {
    return <Skeleton height={300} />;
  }

  return (
    <>
      <PageHeaderWrapper>
        <Title text={PAGE_TITLE} />
        <PageHeaderActionsWrapper>
          <NewCTA
            to={routes.newCustomSignalEvent}
            label={CTA_TEXT}
            testId="new-custom-signal-event-cta"
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <div className="flex items-center my-3">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto self-end"
          count={countData?.count as number}
          entityName="custom signal event"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageOnFilterChange}
          filters={filters}
          pageKey={PAGE_KEY}
          dense
          testId="custom-signal-event-definitions-table"
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
    </>
  );
};

export default CustomSignalEvents;
