import { Handlers } from "duck/agents/ClaimAnalytics/types";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

const updateClaimsFilterSchema = z.object({
  claimsFilter: z.string().optional().describe("Filter string for claims."),
});

type UpdateClaimsFilter = z.infer<typeof updateClaimsFilterSchema>;

const getUpdateClaimsFilterTool = (
  updateClaimsFilterHandler: Handlers["updateClaimsFilter"]
): DynamicStructuredTool => {
  const updateClaimsFilter = ({ claimsFilter }: UpdateClaimsFilter): string => {
    console.debug("Updating claims filters", { claimsFilter });
    if (claimsFilter) {
      updateClaimsFilterHandler(claimsFilter);
    }
    return "success";
  };

  return tool(updateClaimsFilter, {
    name: "updateClaimsFilter",
    description:
      "Call this tool to queue an action that updates the claims filters given a text query. Only call this for claim attributes.",
    schema: updateClaimsFilterSchema,
  });
};

export default getUpdateClaimsFilterTool;
