import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  ByVehicleAgeAgeRequest,
  ExportRequest,
  getFetcher,
  ListAttributesRequest,
  TopContributorsAgeRequest,
} from "shared/api/api";
import { clientV1 } from "shared/api/axios";
import { createURL } from "shared/api/utils";
import {
  CountRequestWithVehiclesFilter,
  Vehicle,
} from "shared/api/vehicles/api";
import { MileageUnit } from "shared/types";

const INSPECTIONS_BASE_ROUTE = "inspections";

export interface Inspection {
  ID: string;
  VIN: string;
  createdAt: string;
  updatedAt: string;
  date: string;
  data: Record<string, string | undefined>;
  externalID: string | null;
  externalURL: string | null;
  notes: string | null;
  status: string | null;
  mileage: number | null;
  mileageUnit: MileageUnit | null;
  monthsInService: number | null;
  daysInService: number | null;
  totalEngineHours: number | null;
}

export interface ListInspectionsRequest extends APIPaginatedRequest {
  vehiclesFilter?: string;
  analytics?: boolean;
  mileageUnit?: MileageUnit;
}

// List & count
export const listInspectionsRequestURI = ({
  ...params
}: ListInspectionsRequest): string =>
  clientV1.getUri({
    method: "GET",
    url: INSPECTIONS_BASE_ROUTE,
    params,
  });

export const listInspectionsCountRequestURI = ({
  ...params
}: CountRequestWithVehiclesFilter): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([INSPECTIONS_BASE_ROUTE, "count"]),
    params,
  });

// Inspections values
export const listInspectionsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listInspectionsValuesRequestURI(args));

const listInspectionsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([INSPECTIONS_BASE_ROUTE, "values", fieldName]),
    params,
  });

// Export
const getInspectionsExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([INSPECTIONS_BASE_ROUTE, "export", type]),
    params,
  });

export const getInspectionsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getInspectionsExportRequestURI(args), {
    responseType: "blob",
  });

export interface InspectionFiltersRequest {
  inspectionsFilter?: string;
  vehiclesFilter?: string;
  signalEventOccurrencesFilter?: string;
}

// Metrics
export interface InspectionsMetrics {
  totalInspections: number;
  totalCost: number;
  cumulativeIPTV: number;
}

export const getInspectionsMetricsRequestURI = (
  params: InspectionFiltersRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([INSPECTIONS_BASE_ROUTE, "metrics"]),
    params,
  });

export interface InspectionsMetricsHistory {
  date: string;
  rollingIPTV: number;
  cumulativeIPTV: number;
  vehicleCount: number;
  rollingInspectionCount: number;
  cumulativeInspectionCount: number;
}

export const getInspectionsMetricsHistoryRequestURI = (
  params: InspectionFiltersRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([INSPECTIONS_BASE_ROUTE, "metricsHistory"]),
    params,
  });

// Single inspection
export interface GetInspectionRequest {
  ID: string;
  mileageUnit: MileageUnit;
}

export const getInspectionRequestURI = ({
  ID,
  ...params
}: GetInspectionRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([INSPECTIONS_BASE_ROUTE, ID]),
    params,
  });

export const getInspection = (args: GetInspectionRequest) =>
  getFetcher<Inspection>(getInspectionRequestURI(args));

// Inspection attributes
export const listInspectionAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([INSPECTIONS_BASE_ROUTE, "attributes"]),
    params,
  });

// Top Contributors
export interface InspectionsTopContributorsRequest
  extends APIPaginatedRequest,
    InspectionFiltersRequest,
    TopContributorsAgeRequest {
  groupByAttribute: string;
}

export interface InspectionsTopContributor {
  groupByAttributeValue: string | number | boolean | null;
  IPTV: number;
  IPTV30DayChangeRatio: number | null;
  DPTV: number;
  DPTV30DayChangeRatio: number | null;
  numInspections: number;
  numVehicles: number;
  exposureBucket: number | null;
}

export const getInspectionsTopContributorsRequestURI = ({
  groupByAttribute,
  ...otherParams
}: InspectionsTopContributorsRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([
      INSPECTIONS_BASE_ROUTE,
      "topContributors",
      groupByAttribute,
    ]),
    params: otherParams,
  });

const getInspectionsTopContributorsExportRequestURI = ({
  IDs,
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([
      INSPECTIONS_BASE_ROUTE,
      "topContributors",
      ...IDs,
      "export",
      type,
    ]),
    params,
  });

export const getInspectionsTopContributorsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getInspectionsTopContributorsExportRequestURI(args), {
    responseType: "blob",
  });

// Associated Vehicles
export interface InspectionAssociatedVehicle extends Vehicle {
  numRelatedInspectionOccurrences: number;
  lastRelatedInspectionOccurrenceAt: string;
  firstRelatedInspectionOccurrenceAt: string;
}

export interface InspectionAssociatedVehiclesRequest
  extends APIPaginatedRequest,
    InspectionFiltersRequest {}

export const listAssociatedVehiclesRequestURI = (
  params: InspectionAssociatedVehiclesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([INSPECTIONS_BASE_ROUTE, "associatedVehicles"]),
    params,
  });

export const countAssociatedVehiclesRequestURI = (
  params: InspectionAssociatedVehiclesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([INSPECTIONS_BASE_ROUTE, "associatedVehicles", "count"]),
    params,
  });

const getAssociatedVehiclesExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([
      INSPECTIONS_BASE_ROUTE,
      "associatedVehicles",
      "export",
      type,
    ]),
    params,
  });

export const getAssociatedVehiclesExport = (args: ExportRequest) =>
  getFetcher<Blob>(getAssociatedVehiclesExportRequestURI(args), {
    responseType: "blob",
  });

// By Vehicle Age
export interface InspectionsByVehicleAgeAgeRequest
  extends InspectionFiltersRequest,
    ByVehicleAgeAgeRequest {}

export const getInspectionsByVehicleAgeTimelineRequestURI = (
  params: InspectionFiltersRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([INSPECTIONS_BASE_ROUTE, "inspectionsByVehicleAgeTimeline"]),
    params,
  });

const getInspectionsByVehicleAgeTimelineExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([
      INSPECTIONS_BASE_ROUTE,
      "inspectionsByVehicleAgeTimeline",
      "export",
      type,
    ]),
    params,
  });

export const getInspectionsByVehicleAgeTimelineExport = (args: ExportRequest) =>
  getFetcher<Blob>(getInspectionsByVehicleAgeTimelineExportRequestURI(args), {
    responseType: "blob",
  });

// Associated signal events
export interface InspectionAssociatedSignalEvent {
  IPTV: number;
  numAssociatedInspections: number;
  numAssociatedVehicles: number;
  percentInspectionsWithPreceding30DaysSignalEvent: number;
  signalEventDescription: string | null;
  signalEventID: string;
  totalEventOccurrences: number;
  associationStrength: number | null;
}

export interface InspectionAssociatedSignalEventsRequest
  extends APIPaginatedRequest,
    InspectionFiltersRequest {
  signalEventsTimeWindow?: number;
}

export const listAssociatedSignalEventsRequestURI = (
  params: InspectionAssociatedSignalEventsRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([INSPECTIONS_BASE_ROUTE, "associatedSignalEvents"]),
    params,
  });
