import { Dispatch, SetStateAction, useState } from "react";
import Skeleton from "react-loading-skeleton";

import {
  SignalEventsTopContributor,
  SignalEventsTopContributorsRequest,
} from "shared/api/signalEvents/api";
import { useListSignalEventsTopContributors } from "shared/api/signalEvents/hooks";
import { getSortFilter } from "shared/api/utils";
import { NO_DATA } from "shared/constants";
import { TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR } from "shared/schemas/constants";
import { useGroupByAttributes } from "shared/schemas/hooks";
import {
  camelCaseToTitle,
  formatNumber,
  isNumeric,
  roundToNDecimals,
} from "shared/utils";

import { useBarSelection } from "pages/ClaimAnalytics/tabPages/TopContributors/hooks";
import { canShowDescription } from "pages/ClaimAnalytics/tabPages/TopContributors/utils";
import { GROUP_BY_ATTRIBUTE_CHART_NULL_REPLACEMENT } from "pages/constants";
import { SignalEventsFiltersProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";
import {
  getTopContributorsChartTitle,
  prepareTopContributorsData,
} from "pages/utils";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import BarChart from "features/ui/charts/BarChart";
import ChartActions, {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import { getAxisLabel, getSelectedOptionId } from "features/ui/charts/utils";
import DescriptionColumn from "features/ui/DescriptionColumn";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { onFilterChangeCallback } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";

import { YAxisVal } from "./ChartActions";

const CHART_DATA_LIMIT = 15;
const BASE_Y_AXIS_LABEL_WIDTH = 10;
const LETTER_WIDTH = 8;

interface TopContributorsChartProps extends SignalEventsFiltersProps {
  selectedGroupByAttribute: SelectOption;
  filters?: FilterGroupState;
  onVehiclesFiltersChange?: onFilterChangeCallback;
  onSignalEventsFiltersChange?: onFilterChangeCallback;
  selectedOptions: SelectedChartOptions[];
  setSelectedOptions: Dispatch<SetStateAction<SelectedChartOptions[]>>;
  actions: ChartAction[];
  selectedByVehicleAgeExposure?: string;
  selectedByVehicleAgeExposureBucket?: number;
  onBadRequest: () => void;
}

const TopChart = ({
  selectedGroupByAttribute,
  filters,
  vehiclesFilters,
  signalEventsFilters,
  onVehiclesFiltersChange,
  onSignalEventsFiltersChange,
  actions,
  selectedOptions,
  setSelectedOptions,
  onBadRequest,
  selectedByVehicleAgeExposure,
  selectedByVehicleAgeExposureBucket,
}: TopContributorsChartProps) => {
  // this helps us avoid overflowing y-axis labels
  // we could/should incorporate this into the charts by default if required
  const [longestYTick, setLongestYTick] = useState("");

  const yAxisKey = getSelectedOptionId(selectedOptions, "y") as YAxisVal;

  const requestParams: SignalEventsTopContributorsRequest = {
    sort: getSortFilter({ [yAxisKey]: "desc" }),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    signalEventOccurrencesFilter: getFiltersQuery(signalEventsFilters),
    filter: getFiltersQuery(filters),
    limit: CHART_DATA_LIMIT,
    groupBy: selectedGroupByAttribute.id as string,
    byVehicleAgeExposure: selectedByVehicleAgeExposure,
    byVehicleAgeExposureBucket: selectedByVehicleAgeExposureBucket,
  };

  const {
    selectedBars,
    showContextMenu,
    handleOnChartClick,
    handleOnBarClick,
    onBarRightClick,
    contextMenu,
  } = useBarSelection({
    selectedGroupByAttribute,
    onVehiclesFiltersChange,
    onSignalEventsFiltersChange,
  });

  const { data, isLoading, error } = useListSignalEventsTopContributors({
    ...requestParams,
    limit: CHART_DATA_LIMIT,
  });

  const selectedGroupByAttributeDisplayName =
    useGroupByAttributes("signalEventOccurrence").find(
      ({ ID }) => ID === selectedGroupByAttribute.id.toString()
    )?.displayName || camelCaseToTitle(selectedGroupByAttribute.id as string);

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  if (isLoading) {
    return (
      <Card>
        <Skeleton height={400} />
      </Card>
    );
  }

  if (!data) return NO_DATA;

  const graphData = prepareTopContributorsData<SignalEventsTopContributor>(
    data,
    yAxisKey
  );

  const tickFormatter = (val: string | number) => {
    const formattedTick = val.toString();
    if (longestYTick.length < formattedTick.length) {
      setLongestYTick(formattedTick);
    }
    return formattedTick;
  };

  const yAxisTickLength =
    longestYTick.length * LETTER_WIDTH + BASE_Y_AXIS_LABEL_WIDTH;

  const yAxisLabel = getAxisLabel(actions, "y", yAxisKey);
  const title = getTopContributorsChartTitle(
    actions,
    selectedOptions,
    selectedByVehicleAgeExposure,
    selectedByVehicleAgeExposureBucket
  );

  return (
    <Card>
      <ChartActionsWrap
        id="signalEvents-top-contributors"
        chartTitle={title}
        contentClassName="w-[350px]"
      >
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      {showContextMenu && contextMenu}
      {data.length === 0 && NO_DATA}
      {data.length > 0 && (
        <BarChart
          height={400}
          width="100%"
          selectedBars={selectedBars}
          onBarClick={handleOnBarClick}
          onBarRightClick={onBarRightClick}
          onChartClick={handleOnChartClick}
          data={graphData}
          xAxisKey={TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR}
          yAxisLabel={yAxisLabel}
          yAxisKey="dimension"
          yAxisProps={{
            tickFormatter: tickFormatter,
            width: yAxisTickLength,
          }}
          xAxisProps={{
            tickFormatter: (value) => {
              if (!value) {
                return GROUP_BY_ATTRIBUTE_CHART_NULL_REPLACEMENT;
              } else if (isNumeric(value)) {
                return roundToNDecimals(Number(value), 2);
              }
              return value;
            },
          }}
          tooltipProps={{
            labelFormatter: (value) => {
              if (isNumeric(value)) {
                value = roundToNDecimals(Number(value), 2);
              }
              return (
                <div>
                  {selectedGroupByAttributeDisplayName}: {value}
                  {canShowDescription(
                    selectedGroupByAttribute.id.toString()
                  ) && (
                    <div>
                      Description:{" "}
                      <DescriptionColumn
                        fieldName={selectedGroupByAttribute.id.toString()}
                        fieldValue={value}
                        textOnly
                      />
                    </div>
                  )}
                </div>
              );
            },
            formatter: (value) =>
              !isNaN(value) ? formatNumber(value, 2) : value,
          }}
        />
      )}
    </Card>
  );
};

export default TopChart;
