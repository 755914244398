import { useCustomLocalStorageState } from "shared/hooks";

import { ClaimsFiltersProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import { useByVehicleAgeOptions } from "pages/hooks";

import { SelectedChartOptions } from "features/ui/charts/ChartActions";
import { getDefaultActions } from "features/ui/charts/utils";

import ByVehicleAgeChart from "./ByVehicleAgeChart";
import ByVehicleAgeTable from "./ByVehicleAgeTable";
import { useByVehicleAgeChartActions } from "./hooks";

const CHART_KEY = "claimAnalyticsByVehicleAgeChartOptions";

const ByVehicleAge = ({
  claimsFilters,
  vehiclesFilters,
  onBadRequest,
}: ClaimsFiltersProps) => {
  const actions = useByVehicleAgeChartActions();

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(CHART_KEY, { defaultValue: getDefaultActions(actions) });

  const byVehicleAgeData = useByVehicleAgeOptions(selectedOptions, actions);

  return (
    <div className="mt-5">
      <ByVehicleAgeChart
        claimsFilters={claimsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        actions={actions}
        byVehicleAgeData={byVehicleAgeData}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
      />
      <ByVehicleAgeTable
        claimsFilters={claimsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        byVehicleAgeData={byVehicleAgeData}
      />
    </div>
  );
};

export default ByVehicleAge;
