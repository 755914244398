import Skeleton from "react-loading-skeleton";

import { useListRepairsByVehicleAgeTimeline } from "shared/api/repairs/hooks";
import { NO_DATA } from "shared/constants";
import { EventTypeEnum } from "shared/types";

import { RepairsByVehicleAgeChartProps } from "pages/RepairAnalytics/RepairAnalyticsTabs";
import ByVehicleAgeChartTooltip from "pages/shared/ByVehicleAgeChartTooltip";
import {
  generateByVehicleAgeYAxisLines,
  prepareByVehicleAgeDataByTimestamp,
} from "pages/utils";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import ChartActions from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import LineChart from "features/ui/charts/LineChart";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";

const ByVehicleAgeChart = ({
  repairsFilters,
  vehiclesFilters,
  onBadRequest,
  actions,
  byVehicleAgeData,
  selectedOptions,
  setSelectedOptions,
}: RepairsByVehicleAgeChartProps) => {
  const {
    byVehicleAgeBirthday,
    byVehicleAgeBirthdayValue,
    byVehicleAgeExposure,
    granularity,
    yAxisKey,
    yAxisValue,
  } = byVehicleAgeData;

  const { data, isLoading, error } = useListRepairsByVehicleAgeTimeline({
    repairsFilter: getFiltersQuery(repairsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    byVehicleAgeBirthday,
    byVehicleAgeExposure,
    granularity,
  });

  if (isLoading) {
    return (
      <Card>
        <Skeleton height={400} width="100%" />
      </Card>
    );
  }

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  if (!data) return NO_DATA;

  const chartData = prepareByVehicleAgeDataByTimestamp(data, byVehicleAgeData);
  const yAxisLines = generateByVehicleAgeYAxisLines(data, byVehicleAgeData);

  const chartTitle = `${yAxisValue} by ${byVehicleAgeBirthdayValue}`;

  return (
    <Card>
      <ChartActionsWrap chartTitle={chartTitle} contentClassName="w-[350px]">
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      {data.length === 0 && NO_DATA}
      {data.length > 0 && (
        <LineChart
          height={400}
          width="100%"
          data={chartData}
          xAxisKey="ts"
          yAxisLines={yAxisLines}
          yAxisLabel={yAxisValue}
          xAxisLabel={byVehicleAgeBirthdayValue}
          connectNulls={true}
          tooltipProps={{
            content: (props) => (
              <ByVehicleAgeChartTooltip
                {...props}
                yAxisKey={yAxisKey}
                yAxisLabel={yAxisValue}
                eventType={EventTypeEnum.REPAIR}
              />
            ),
          }}
          toggleableLines={true}
        />
      )}
    </Card>
  );
};

export default ByVehicleAgeChart;
