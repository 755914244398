import { useCallback, useMemo } from "react";

import {
  getAssociatedVehiclesExport,
  SignalEventsAssociatedVehicle,
  SignalEventsAssociatedVehiclesRequest,
} from "shared/api/signalEvents/api";
import {
  useSignalEventsAssociatedVehicles,
  useSignalEventsAssociatedVehiclesCount,
} from "shared/api/signalEvents/hooks";
import { getSortFilter } from "shared/api/utils";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { SortBy } from "shared/types";
import { getTenantMileageUnit } from "shared/utils";

import { SignalEventsFiltersProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";
import { getColumnsToShow } from "pages/Vehicles/utils";
import { VINEventTimelineDateLink } from "pages/VINView/ServiceRecords/VINEventTimelineDateLink";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import StringList from "features/ui/StringList";
import Table, { OnSortParams, SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";

const PAGE_KEY = "signalEventsAnalytics-associated-vehicles";
const DEFAULT_SORT: SortBy = { numRelatedSignalEventOccurrences: "desc" };
const LIMIT = 500;
const TABLE_HEIGHT_PX = 500;

const COLUMNS = [
  "VIN",
  "numRelatedSignalEventOccurrences",
  "vehicleMake",
  "vehicleModel",
  "vehicleModelYear",
  "vehicleManufacturedAt",
  "engineModel",
  "tags",
  "manufacturePlantName",
  "lastKnownDealerCountry",
  "lastKnownDealerState",
  "lastKnownDealerCity",
  "lastKnownDealerID",
  "lastKnownDealer.ID",
  "lastKnownDealer.name",
  "lastKnownDealer.countryCode",
  "lastKnownDealer.provinceCode",
  "lastKnownDealer.city",
  "lastKnownDealer.region",
  "lastKnownDealer.postalCode",
  "lastKnownDealer.contact",
  "saleDealer.ID",
  "saleDealer.name",
  "saleDealer.city",
  "saleDealer.provinceCode",
  "saleDealer.countryCode",
  "saleDealer.contact",
];

const AssociatedVehicles = ({
  signalEventsFilters,
  vehiclesFilters,
  onBadRequest,
}: SignalEventsFiltersProps) => {
  const { schema, getDisplayLabel } = useVehiclesSchema();

  const formatRow = useCallback(
    (vehicle: SignalEventsAssociatedVehicle) => {
      const {
        VIN,
        tags,
        firstRelatedSignalEventOccurrenceAt,
        lastRelatedSignalEventOccurrenceAt,
      } = vehicle;

      return {
        ...vehicle,
        VIN: (
          <VINEventTimelineDateLink
            VIN={VIN}
            date={firstRelatedSignalEventOccurrenceAt}
            dateTo={lastRelatedSignalEventOccurrenceAt}
          />
        ),
        tags: tags && (
          <StringList title={getDisplayLabel("tags", "Tags")} values={tags} />
        ),
      };
    },
    [getDisplayLabel]
  );

  const columnsSchema: SchemaEntry[] = schema.filter((schemaEntry) =>
    COLUMNS.includes(schemaEntry.accessor)
  );

  // manually add related signal events to second column
  columnsSchema.splice(1, 0, {
    dataType: DataType.NUMBER,
    accessor: "numRelatedSignalEventOccurrences",
    label: "Related Signal Events",
    sortable: true,
  });

  // manually add last related signal event occurrence to third column
  columnsSchema.splice(2, 0, {
    dataType: DataType.DATE,
    accessor: "lastRelatedSignalEventOccurrenceAt",
    label: "Latest occurrence",
    sortable: true,
  });

  const columnsToShow = [
    ...getColumnsToShow(schema, undefined),
    "numRelatedSignalEventOccurrences",
    "lastRelatedSignalEventOccurrenceAt",
  ];

  const filteredSchema = columnsSchema.filter(({ accessor }: SchemaEntry) =>
    columnsToShow.includes(accessor)
  );

  const {
    manageOnFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const allVehiclesFilters = mergeFilterGroupStates(filters, vehiclesFilters);

  const vehiclesFilterString = getFiltersQuery(allVehiclesFilters);
  const signalEventsFilterString = getFiltersQuery(signalEventsFilters);

  const requestParams: SignalEventsAssociatedVehiclesRequest = {
    sort: getSortFilter(sort),
    filter: vehiclesFilterString,
    signalEventOccurrencesFilter: signalEventsFilterString,
    limit: LIMIT,
    mileageUnit: getTenantMileageUnit(),
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useSignalEventsAssociatedVehicles(requestParams);

  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data, formatRow]);

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useSignalEventsAssociatedVehiclesCount({
    filter: vehiclesFilterString,
    signalEventOccurrencesFilter: signalEventsFilterString,
  });

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <>
      <div className="flex items-center my-1">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto"
          count={countData?.count as number}
          entityName="vehicle"
          isLoading={countIsLoading}
          error={!!countError}
          prefix={`Showing ${data?.length} out of `}
        />
        <DownloadAction
          disabled={downloadDisabled}
          downloadFunc={getAssociatedVehiclesExport}
          fileName="associated-vehicles"
          requestParams={{
            ...requestParams,
            limit: MAX_ROWS_DOWNLOAD_LIMIT,
          }}
          count={countData?.count as number}
          entityName="vehicle"
          filters={allVehiclesFilters}
        />
      </div>
      {!error && (
        <Table
          {...paginationData}
          data={formattedData}
          schema={filteredSchema}
          isLoading={isLoading}
          loadingRows={LIMIT}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageOnFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
          scrollHeight={TABLE_HEIGHT_PX}
        />
      )}
      {error && (
        <APIError
          error={error}
          onBadRequest={() => {
            resetFilterSortState();
            onBadRequest();
          }}
        />
      )}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default AssociatedVehicles;
