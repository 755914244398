import { useFlags } from "launchdarkly-react-client-sdk";

import {
  useByVehicleAgeBirthdayOptions,
  useClaimByVehicleAgeExposureOptions,
} from "pages/hooks";
import { getByVehicleAgeChartOptions } from "pages/utils";

import { cumulativeCPV, cumulativeIPTV } from "features/ui/charts/actions";
import { ChartAction } from "features/ui/charts/ChartActions";
import { Option, SelectOption } from "features/ui/Select";

/**
 * This hook is used by the ByVehicleAge chart to build the available chart actions.
 * It is also used by Duck to provide context to the agent.
 */
export const useByVehicleAgeChartActions = (): ChartAction<Option>[] => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();

  const yAxisOptions: SelectOption[] = [
    cumulativeIPTV,
    warrantyClaimsCostFF && cumulativeCPV,
  ].filter(Boolean);

  return getByVehicleAgeChartOptions(
    yAxisOptions,
    useByVehicleAgeBirthdayOptions(),
    useClaimByVehicleAgeExposureOptions()
  );
};
