import { RequestParams } from "duck/agents/ClaimAnalytics/types";
import { hasAllEnvVarsForViaDuck } from "duck/ui/utils";
import { Client } from "langsmith";
import { AIMessage, HumanMessage } from "@langchain/core/messages";
import { LangChainTracer } from "@langchain/core/tracers/tracer_langchain";

import {
  LC_API_KEY,
  LC_ENDPOINT,
  LC_PROJECT_NAME,
  MODELSPEC,
} from "./constants";
import getAgentExecutor from "./getAgentExecutor";

// Create Langchain client and tracer
const client = new Client({ apiKey: LC_API_KEY, apiUrl: LC_ENDPOINT });
const tracer = new LangChainTracer({ client, projectName: LC_PROJECT_NAME });

// Main function to call agent
const callAgent = async (params: RequestParams): Promise<void> => {
  if (!hasAllEnvVarsForViaDuck()) {
    console.warn(
      "Missing environment variables. Please set REACT_APP_LANGCHAIN_ENDPOINT, REACT_APP_LANGCHAIN_API_KEY, REACT_APP_LANGCHAIN_PROJECT, and REACT_APP_OPENAI_API_KEY."
    );
    throw new Error("Missing environment variables.");
  }

  const agentExecutor = await getAgentExecutor(params, false);

  const messages = params.messageHistory.map((message) =>
    message.author === "human"
      ? new HumanMessage(message.message)
      : new AIMessage(message.message)
  );
  messages.push(new HumanMessage(params.text));

  const response = await agentExecutor.invoke(
    {
      messages,
      pageState: params.currentState,
    },
    {
      configurable: { thread_id: params.threadId },
      callbacks: [tracer],
      runName: "Claim Analytics Expert",
      tags: ["claim_analytics_expert"],
      metadata: {
        lsModelName: MODELSPEC.modelName,
        lsProvider: "openai",
        lsTemperature: MODELSPEC.temperature,
      },
    }
  );

  console.log("Response:", response);
};

export default callAgent;
