import { useEffect, useState } from "react";

import { DataElement } from "features/ui/charts/types";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { mergeFilterGroupStates } from "features/ui/Filters/FilterBuilder/utils";
import { SelectOption } from "features/ui/Select";

import { DASHBOARD_GROUP_BY_KEY } from "./constants";
import { getFilterFromColumns } from "./utils";

interface Props {
  selectedGroupByAttribute: SelectOption;
  selectedBucketByKey: string;
  menuOffsetElement?: Element | null;
  nameIDMapping: { [key: string]: string };
  updateFilters?: (filters: FilterGroupState) => void;
  existingFilters?: FilterGroupState;
}

export const useBarSelection = ({
  selectedGroupByAttribute,
  selectedBucketByKey,
  menuOffsetElement,
  nameIDMapping,
  updateFilters,
  existingFilters,
}: Props) => {
  // reset selected bars when group by attribute changes
  useEffect(() => {
    resetSelectedBars();
  }, [selectedGroupByAttribute]);

  // reset selected columns when group/bucket by attribute changes
  useEffect(() => {
    resetSelectedColumns();
  }, [selectedBucketByKey, selectedGroupByAttribute]);

  // selectedBars refers to bar groups and selectedColumns refers to single bars inside the bar group
  const [selectedBars, setSelectedBars] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<{
    [key: string]: string[];
  }>({});
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  // Toggle currently selected bars
  // make sure to stopPropagation so that the chart doesn't get the click event fired, which resets selected bars
  const handleOnBarClick = (
    row: DataElement,
    event: React.MouseEvent<SVGPathElement, MouseEvent>
  ) => {
    event.stopPropagation();

    const clickedBar = row[DASHBOARD_GROUP_BY_KEY];
    setSelectedBars((prevSelectedBars) => {
      if (!prevSelectedBars) return [clickedBar];

      const isSelected = prevSelectedBars.includes(clickedBar);
      if (isSelected) {
        return prevSelectedBars.filter((bar) => bar !== clickedBar);
      } else {
        return [...prevSelectedBars, clickedBar];
      }
    });

    const clickedColumn: string = row?.["tooltipPayload"]?.[0]?.["name"] ?? "";
    setSelectedColumns((prevSelectedColumns) => {
      // we create a deep copy since we don't want to mutate the original object
      const updatedColumns = JSON.parse(JSON.stringify(prevSelectedColumns));
      if (!(clickedBar in updatedColumns)) {
        updatedColumns[clickedBar] = [clickedColumn];
        return updatedColumns;
      }

      const isSelected = updatedColumns[clickedBar].includes(clickedColumn);
      if (isSelected) {
        updatedColumns[clickedBar] = updatedColumns[clickedBar].filter(
          (col: string) => col !== clickedColumn
        );
        // remove the key if there are no values left
        if (updatedColumns[clickedBar].length === 0) {
          delete updatedColumns[clickedBar];
        }
      } else {
        updatedColumns[clickedBar].push(clickedColumn);
      }
      return updatedColumns;
    });
    setShowContextMenu(false);
  };

  const resetSelectedBars = () => {
    setSelectedBars([]);
  };

  const resetSelectedColumns = () => {
    setSelectedColumns({});
  };

  const handleOnChartClick = () => {
    resetSelectedBars();
    resetSelectedColumns();
    // we close the context menu on any mouse click anywhere on the chart..
    if (showContextMenu) setShowContextMenu(false);
  };

  const onSelectedBarsApplyAsFilter = () => {
    if (selectedBars.length > 0 && updateFilters) {
      const newColumnsFilter = getFilterFromColumns(
        selectedColumns,
        selectedGroupByAttribute.id.toString(),
        selectedBucketByKey,
        nameIDMapping
      );

      const mergedFilters = mergeFilterGroupStates(
        newColumnsFilter,
        existingFilters
      );
      updateFilters(mergedFilters);
    }

    resetSelectedBars();
    resetSelectedColumns();
    setShowContextMenu(false);
  };

  const onBarRightClick = (
    _x: DataElement,
    _y: number,
    event: React.MouseEvent<SVGPathElement, MouseEvent>
  ) => {
    event.preventDefault();

    // If the user right-clicks on a bar and no bars are selected, automatically select the right-clicked bar
    if (selectedBars.length === 0) {
      handleOnBarClick(_x, event);
    }

    const elementRect = menuOffsetElement?.getBoundingClientRect();
    const offsetX = elementRect ? elementRect.left : 0;
    const offsetY = elementRect ? elementRect.top : 0;

    setContextMenuPosition({
      x: event.clientX - offsetX,
      y: event.clientY - offsetY,
    });
    setShowContextMenu(true);
  };

  const contextMenu = (
    <div
      className="bg-white shadow border px-4 py-3 absolute z-10"
      style={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}
    >
      <ul className="flex flex-col space-y-2">
        <li
          onClick={onSelectedBarsApplyAsFilter}
          className="text-sm hover:opacity-75 cursor-pointer"
        >
          Include selected values as page filter
        </li>
      </ul>
    </div>
  );

  return {
    selectedBars,
    selectedColumns,
    showContextMenu,
    handleOnBarClick,
    onBarRightClick,
    contextMenu,
    handleOnChartClick,
  };
};
