import React, { useCallback, useEffect, useMemo } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { generatePath, Link } from "react-router-dom";

import {
  getSuggestedIssuesExport,
  SuggestedIssue,
} from "shared/api/suggestedIssues/api";
import {
  useListSuggestedIssues,
  useListSuggestedIssuesCount,
} from "shared/api/suggestedIssues/hooks";
import {
  APIFilter,
  applyAdditionalSorting,
  getSortFilter,
} from "shared/api/utils";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import useSuggestedIssuesSchema from "shared/schemas/useSuggestedIssuesSchema";
import { ValueType } from "shared/types";
import { cloneObject } from "shared/utils";

import { SUGGESTED_ISSUES_CHART_ACTIONS } from "pages/Issues/constants";
import IssueGroupCell from "pages/Issues/IssueGroupCell";
import {
  ClaimFilterPresenter,
  PopulationFilterPresenter,
  SignalEventFilterPresenter,
} from "pages/Issues/IssuePresenters";
import {
  getAxisKeyLabelFromActions,
  getMeasureLabel,
} from "pages/Issues/utils";

import APIError from "features/ui/APIError";
import { SelectedChartOptions } from "features/ui/charts/ChartActions";
import DownloadAction from "features/ui/DownloadAction";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  getFilterGroupStateTopLevelRowAttributes,
  getFiltersQuery,
  mergeFilterGroupStates,
  removeAttributesFromFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterStateCount } from "features/ui/Filters/utils";
import StringList from "features/ui/StringList";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

interface Props {
  topFilterSortState: UseFilterSortState;
  selectedChartOptions: SelectedChartOptions[];
  selectedBarFilters?: FilterGroupState | undefined;
  staticFilters?: APIFilter[];
}

const ROWS_PER_PAGE = 10;
const PAGE_KEY = "suggested-issues-table";
const NO_DATA_TEXT = "No Suggested Issues yet.";
const MAX_SI_DOWNLOAD_LIMIT = 2000;

const SuggestedIssuesTable = ({
  topFilterSortState,
  selectedChartOptions,
  selectedBarFilters,
  staticFilters,
}: Props) => {
  const { issuePublishing } = useFlags();

  const { getDisplayLabel } = useClaimsSchema();

  const { schema } = useSuggestedIssuesSchema();

  const { axisKey: measureKey, axisValue: measureLabel } =
    getAxisKeyLabelFromActions(
      selectedChartOptions,
      SUGGESTED_ISSUES_CHART_ACTIONS,
      "measure"
    );

  const { axisKey: lookbackWindow } = getAxisKeyLabelFromActions(
    selectedChartOptions,
    SUGGESTED_ISSUES_CHART_ACTIONS,
    "lookbackWindow"
  );

  const { axisKey: valueType } = getAxisKeyLabelFromActions(
    selectedChartOptions,
    SUGGESTED_ISSUES_CHART_ACTIONS,
    "valueType"
  );

  const valueTypeKey = valueType as ValueType;

  const idIdx = schema.findIndex(({ accessor }) => accessor === "ID");
  if (measureKey !== "count" && idIdx !== -1) {
    const metricSchemaEntry: SchemaEntry = {
      label: getMeasureLabel(valueTypeKey, measureKey, undefined, measureLabel),
      accessor: "metric.value",
      dataType:
        valueTypeKey === "percentage"
          ? DataType.RATIO_PERCENTAGE
          : DataType.NUMBER,
      sortable: true,
    };

    schema.splice(idIdx + 1, 0, metricSchemaEntry);
  }

  const {
    manageOnSortChange,
    sort,
    filters: topFilters,
    resetFilterSortState,
    updateFilters,
  } = topFilterSortState;

  const {
    manageOnFilterChange,
    resetFilters,
    filters: tableFilters,
    initialized: filtersInitialized,
    updateFilters: updateTableFilters,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
  });

  // The following useEffect modifies filters if two users use the same URL but have
  // different FF configs
  useEffect(() => {
    // 'published' filter should be removed if 'issuePublishing' FF is set to False
    let newFilters = cloneObject(topFilters);
    if (
      !issuePublishing &&
      getFilterGroupStateTopLevelRowAttributes(topFilters).includes("published")
    ) {
      newFilters = removeAttributesFromFilterGroupState(newFilters, [
        "published",
      ]);
    }

    let newTableFilters = cloneObject(tableFilters);
    if (
      !issuePublishing &&
      getFilterGroupStateTopLevelRowAttributes(tableFilters).includes(
        "published"
      )
    ) {
      newTableFilters = removeAttributesFromFilterGroupState(newTableFilters, [
        "published",
      ]);
    }

    if (getFilterStateCount(topFilters) !== getFilterStateCount(newFilters)) {
      updateFilters(newFilters);
    }

    if (
      getFilterStateCount(tableFilters) !== getFilterStateCount(newTableFilters)
    ) {
      updateTableFilters(newFilters);
    }

    // There is no need to include updateFilters in deps, it just activates useEffect way too many times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilters, topFilters, issuePublishing]);

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const allFilters = mergeFilterGroupStates(
    topFilters,
    tableFilters,
    selectedBarFilters
  );
  const allFiltersQuery = getFiltersQuery(allFilters, staticFilters);

  const { data, isLoading, headers, error, ...paginationData } =
    useListSuggestedIssues({
      sort: getSortFilter(applyAdditionalSorting(sort), schema),
      filter: allFiltersQuery,
      metricType: measureKey,
      lookbackWindow: lookbackWindow ? parseInt(lookbackWindow) : undefined,
      valueType: valueType as ValueType,
      limit: ROWS_PER_PAGE,
    });

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useListSuggestedIssuesCount({
    filter: allFiltersQuery,
  });

  const formatRow = useCallback(
    (suggestedIssueListing: SuggestedIssue) => {
      const {
        ID,
        updated,
        atRiskPopulationFilter,
        comparisonPopulationFilter,
        subgroups,
        assignedGroupID,
        assignedGroup,
        promotedTo,
        statusObj,
      } = suggestedIssueListing;

      return {
        ...suggestedIssueListing,
        ID: (
          <Link
            to={{
              pathname: generatePath(routes.suggestedIssue, {
                id: encodeURIComponent(ID),
                date: encodeURIComponent(updated),
              }),
            }}
            className="text-metabase-blue hover:underline text-wrap"
          >
            {ID}
          </Link>
        ),
        assignedGroupID: (
          <IssueGroupCell
            assignedGroupID={assignedGroupID}
            assignedGroup={assignedGroup}
          />
        ),
        atRiskPopulationFilter: (
          <PopulationFilterPresenter
            populationFilter={atRiskPopulationFilter}
          />
        ),
        comparisonPopulationFilter: (
          <PopulationFilterPresenter
            populationFilter={comparisonPopulationFilter || undefined}
          />
        ),
        claimFilter: <ClaimFilterPresenter issue={suggestedIssueListing} />,
        signalEventOccurrencesFilter: (
          <SignalEventFilterPresenter issue={suggestedIssueListing} />
        ),
        subgroups: (
          <StringList
            title={getDisplayLabel("subgroup", "Subgroups")}
            values={subgroups}
          />
        ),
        promotedTo: (
          <ul>
            {promotedTo.map(({ ID: issueID, name }) => (
              <li key={issueID}>
                <Link
                  to={generatePath(routes.issue, {
                    id: encodeURIComponent(issueID),
                  })}
                  className="text-metabase-blue hover:underline"
                >
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        ),
        statusObj: statusObj?.value,
      };
    },
    [getDisplayLabel]
  );

  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data, formatRow]);

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <>
      <div data-testid="suggested-issues-table">
        <div className="flex items-center mt-3 mb-1">
          <FiltersOverview
            filters={tableFilters}
            tableSchema={schema}
            onFiltersReset={resetFilters}
          />
          <TableCount
            extraClasses="ml-auto"
            count={countData?.count as number}
            entityName="suggested issue"
            isLoading={countIsLoading}
            error={!!countError}
          />
          <DownloadAction
            disabled={downloadDisabled}
            fileName="suggested-issues"
            requestParams={{
              filter: getFiltersQuery(tableFilters, staticFilters),
              limit: MAX_SI_DOWNLOAD_LIMIT,
            }}
            count={countData?.count as number}
            entityName="suggested issue"
            downloadFunc={getSuggestedIssuesExport}
            filters={allFilters}
          />
        </div>
        {!error && (
          <PaginatedTable
            {...paginationData}
            dense
            data={formattedData}
            schema={schema}
            isLoading={isLoading}
            loadingRows={ROWS_PER_PAGE}
            sortBy={sort}
            onSort={handleSorting}
            filtersInitialized={filtersInitialized}
            onFiltersReset={resetFilters}
            onFilterChange={manageOnFilterChange}
            filters={tableFilters}
            staticFilters={staticFilters}
          />
        )}
        {error && (
          <APIError error={error} onBadRequest={resetFilterSortState} />
        )}
        {!error && !isLoading && !formattedData?.length && (
          <div className="py-4 text-gray-400 text-sm">{NO_DATA_TEXT}</div>
        )}
      </div>
    </>
  );
};

export default SuggestedIssuesTable;
