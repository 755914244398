import { Handlers, RequestParams } from "duck/agents/ClaimAnalytics/types";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { BY_VEHICLES_AGE_TAB_KEY } from "pages/constants";
import { byVehicleAgeActionIds, ByVehicleAgeActionIdType } from "pages/utils";

const actionDescriptions: Map<ByVehicleAgeActionIdType, string> = new Map([
  [
    "y",
    "The metric to be displayed on the y-axis of the chart. Default value: 'cumulativeIPTV'.",
  ],
  [
    "x",
    "The vehicle age attribute to be displayed on the x-axis of the chart. Default value: 'vehicleManufacturedAt'.",
  ],
  ["granularity", "The granularity of the chart. Default value: 'month'."],
  ["exposure", "The exposure of the chart. Default value: 'monthsInService'."],
]);

// TODO: Change the keys from yAxis to y and xAxis to x so we don't need this mapping.
// This will require coordination with the data science team.
// https://viaduct-ai.atlassian.net/browse/DUCK-145
const keyMap: Map<ByVehicleAgeActionIdType, string> = new Map([
  ["y", "yAxis"],
  ["x", "xAxis"],
]);

const getGoToClaimsByVehiclesAgeTabTool = (
  changeTabHandler: Handlers["changeTab"],
  setByVehicleAgeChartOptionsHandler: Handlers["setByVehicleAgeChartOptions"],
  byVehicleAgeChartOptions: RequestParams["byVehicleAgeChartOptions"]
): DynamicStructuredTool => {
  const keys = byVehicleAgeActionIds.map(
    (actionId) => keyMap.get(actionId) ?? actionId
  );
  type SchemaDefinition = {
    [K in (typeof keys)[number]]: z.ZodOptional<
      z.ZodEnum<[string, ...string[]]>
    >;
  };

  const schemaDefinition = byVehicleAgeActionIds.reduce((acc, actionId) => {
    const key = keyMap.get(actionId) ?? actionId;
    return {
      ...acc,
      [key]: z
        .enum(byVehicleAgeChartOptions[actionId])
        .optional()
        .describe(actionDescriptions.get(actionId) ?? ""),
    };
  }, {} as SchemaDefinition);

  const goToClaimsByVehiclesAgeTabSchema = z.object(schemaDefinition);

  type GoToClaimsByVehiclesAgeTab = z.infer<
    typeof goToClaimsByVehiclesAgeTabSchema
  >;

  const goToClaimsByVehiclesAgeTab = ({
    yAxis,
    xAxis,
    granularity,
    exposure,
  }: GoToClaimsByVehiclesAgeTab) => {
    console.debug(
      `Navigating to Claims by Vehicles Age tab with yAxis: ${yAxis}, xAxis: ${xAxis}, granularity: ${granularity}, exposure: ${exposure}`
    );
    changeTabHandler(BY_VEHICLES_AGE_TAB_KEY);
    setByVehicleAgeChartOptionsHandler(yAxis, xAxis, granularity, exposure);
    return "success";
  };

  return tool(goToClaimsByVehiclesAgeTab, {
    name: "goToClaimsByVehiclesAgeTab",
    description:
      "Call this tool to queue a navigation to the Claims by Vehicles Age tab action. This tab displays a chart of claims rate (IPTV or CPV) by vehicle age (vehicle or engine manufactured date).",
    schema: goToClaimsByVehiclesAgeTabSchema,
  });
};

export default getGoToClaimsByVehiclesAgeTabTool;
