import {
  DEFAULT_BIRTHDAY,
  DEFAULT_BIRTHDAY_VALUE,
  DEFAULT_EXPOSURE,
  DEFAULT_EXPOSURE_BUCKET,
  DEFAULT_EXPOSURE_VALUE,
  NONE_EXPOSURE,
} from "shared/constants";

import { SelectOption } from "features/ui/Select";

/**
 * Analytics Pages (Claim Analytics, Repair Analytics, Inspection Analytics ..)
 */
export const BY_VEHICLES_AGE_TAB_KEY = "by-vehicle-age";
export const BY_VEHICLE_AGE_TAB_TITLE = "By Vehicle Age";

export const TOP_CONTRIBUTORS_TAB_KEY = "top-contributors";
export const TOP_CONTRIBUTORS_TAB_TITLE = "Top Contributors";

export const ASSOCIATED_VEHICLES_TAB_KEY = "associated-vehicles";
export const ASSOCIATED_VEHICLES_TAB_TITLE = "Associated Vehicles";

export const ASSOCIATED_SIGNAL_EVENTS_TAB_KEY = "associated-signal-events";
export const ASSOCIATED_SIGNAL_EVENTS_TAB_TITLE = "Associated Signal Events";

export const TOP_CONTRIBUTORS_NONE_EXPOSURE: SelectOption = {
  id: NONE_EXPOSURE,
  value: "None",
  label: "None",
};
export const GROUP_BY_ATTRIBUTE_CHART_NULL_REPLACEMENT = "Empty";

export const BY_VEHICLE_AGE_VEHICLE_MANUFACTURED_AT: SelectOption = {
  id: DEFAULT_BIRTHDAY,
  value: DEFAULT_BIRTHDAY_VALUE,
  label: DEFAULT_BIRTHDAY_VALUE,
};

export const MONTHS_IN_SERVICE_EXPOSURE: SelectOption = {
  id: DEFAULT_EXPOSURE,
  value: DEFAULT_EXPOSURE_VALUE,
  label: DEFAULT_EXPOSURE_VALUE,
};

export const MONTHS_IN_SERVICE_EXPOSURE_BUCKET: SelectOption = {
  id: DEFAULT_EXPOSURE_BUCKET,
  value: DEFAULT_EXPOSURE_BUCKET,
  label: DEFAULT_EXPOSURE_BUCKET,
};

/**
 * All pages
 */
export const SUBMIT_CTA_NEW = "Save";
export const SUBMIT_CTA_EDIT = "Update";
export const CANCEL_CTA_TEXT = "Cancel";
