import { GraphStateType } from "duck/agents/common/utils/state";
import { END } from "@langchain/langgraph/web";

/**
 * Respond if the last message is END tool call, END
 * otherwise, continue to the tools node
 * @summary Conditional routing function for the agent
 * @param state
 * @returns An indicator of what the agent should do next
 */
export const shouldContinue = (state: GraphStateType): "tools" | typeof END => {
  const { messages } = state;
  const lastMessage = messages[messages.length - 1];
  if (
    "tool_calls" in lastMessage &&
    Array.isArray(lastMessage.tool_calls) &&
    lastMessage.tool_calls?.length
  ) {
    if (lastMessage.tool_calls[0].name === "END") {
      return END;
    }
    return "tools";
  }

  return "tools";
};
