import { CustomSignalEventDefinition } from "shared/api/customSignalEvents/api";
import { toTitleCase } from "shared/utils";

import {
  ACTIVE_STATUS_LIST,
  ERROR_STATUSES,
} from "pages/CustomSignalEvents/constants";

interface Props {
  customSignalEventDefinition: CustomSignalEventDefinition;
}

const DISABLED_COLOR = "text-gray-400";
const ERROR_COLOR = "text-red-600";
const BASE_COLOR = "text-viaduct-black";

const StatusSection = ({ customSignalEventDefinition }: Props) => {
  const { status } = customSignalEventDefinition;
  // show error status in a list of statuses only if current status is error status
  const filteredStatusList = ERROR_STATUSES.includes(status)
    ? ACTIVE_STATUS_LIST.filter(
        (s) => !ERROR_STATUSES.includes(s) || s === status
      )
    : ACTIVE_STATUS_LIST.filter((s) => !ERROR_STATUSES.includes(s));

  return (
    <div className="h-full pt-2">
      <>
        {filteredStatusList.map((s, idx) => {
          const statusColor = ERROR_STATUSES.includes(s)
            ? ERROR_COLOR
            : BASE_COLOR;
          const textColor = s === status ? statusColor : DISABLED_COLOR;
          return (
            <>
              <span className={textColor}>{toTitleCase(s)}</span>{" "}
              {idx !== filteredStatusList.length - 1 && (
                <span className={DISABLED_COLOR}>{" > "}</span>
              )}
            </>
          );
        })}
      </>
    </div>
  );
};

export default StatusSection;
