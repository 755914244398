import { useState } from "react";
import classNames from "classnames";

import { ChartActionID, ChartActionType } from "shared/types";

import Checkbox from "features/ui/Checkbox";
import DropdownSelect from "features/ui/DropdownSelect";
import { Option, SelectOption } from "features/ui/Select";

import { ChartActionAttributeNameValue } from "./ChartActionAttributeNameValue";
import { getSelectedChartActionOptionName } from "./utils";

export interface ChartActionOption<OptionId = Option>
  extends SelectOption<OptionId> {
  valueFormatter?: (val: any) => {};
  tooltipValueFormatter?: (val: any) => {};
}

interface SecondaryChartOptions {
  id: string;
  values: string[];
}

export interface ChartAction<OptionId = Option> {
  id: ChartActionID;
  title: string;
  textBeforeDropdown?: string;
  textAfterDropdown?: string;
  type: ChartActionType;
  options?: ChartActionOption<OptionId>[];
  secondaryOptions?: SecondaryChartOptions[];
  defaultOptionId?: string;
  className?: string;
  onUpdate?: (selectedName: string, selectedValues: string[]) => void;
}

export interface SelectedChartOptions<OptionId = Option> {
  id: string;
  optionId: OptionId;
}

interface Props {
  title?: string;
  actions: ChartAction[];
  selectedOptions: SelectedChartOptions[];
  onOptionChange: (selectedOptions: SelectedChartOptions[]) => void;
}

export const TITLE = "Chart Settings";

interface BooleanAndDropdownSelectProps {
  action: ChartAction;
  options: ChartActionOption[] | undefined;
  selectedOptions: SelectedChartOptions[];
  onOptionChange: (selectedOptions: SelectedChartOptions[]) => void;
  className?: string;
}

const BooleanAndDropdownSelect = ({
  action,
  options,
  selectedOptions,
  onOptionChange,
  className,
}: BooleanAndDropdownSelectProps) => {
  const { id, textAfterDropdown, textBeforeDropdown } = action;

  const selectedOption = selectedOptions.find((x) => x.id === id)
    ?.optionId as string;

  const [checked, setChecked] = useState<boolean>(selectedOption !== "");
  const [selectedOptionId, setSelectedOptionId] = useState<string>(
    selectedOption !== "" ? selectedOption : (options?.[0]?.id as string)
  );

  const onCheckboxChange = (c: boolean) => {
    const optionId = c ? selectedOptionId : "";
    const newSelectedOptions = selectedOptions.map((obj) =>
      obj.id === id
        ? {
            id,
            optionId,
          }
        : obj
    );

    if (!newSelectedOptions.map(({ id }) => id).includes(id)) {
      newSelectedOptions.push({ id, optionId });
    }

    setChecked(c);
    onOptionChange(newSelectedOptions);
  };

  const selectedOptionLabel = action.options?.find(
    (opt) => opt.id === selectedOptionId
  )?.label as string;

  const onSelectChange = (newOption: SelectOption) => {
    const optionId = newOption ? newOption.id : "";
    const newSelectedOptions = selectedOptions.map((obj) =>
      obj.id === id
        ? {
            id,
            optionId,
          }
        : obj
    );

    if (!newSelectedOptions.map(({ id }) => id).includes(id)) {
      newSelectedOptions.push({ id, optionId });
    }

    setSelectedOptionId(newOption.id as string);
    onOptionChange(newSelectedOptions);
  };

  return (
    <div
      key={id}
      className={classNames(
        "inline-flex items-center space-x-2 text-nowrap",
        className
      )}
    >
      <Checkbox checked={checked} onChange={onCheckboxChange} />
      <span>{textBeforeDropdown}</span>
      <DropdownSelect
        label={selectedOptionLabel}
        options={options}
        onSelect={onSelectChange}
        testId={`chart-action-dropdown-${id}`}
        disabled={!checked}
      />
      <span>{textAfterDropdown}</span>
    </div>
  );
};

const ChartActions = ({
  title = TITLE,
  actions,
  selectedOptions,
  onOptionChange,
}: Props) => {
  const updateSelectedDropdownOptions = (
    id: string,
    selectedOptionId?: string
  ) => {
    const newSelectedActions = selectedOptions.map((obj) =>
      obj.id === id
        ? {
            id,
            optionId: selectedOptionId as string,
          }
        : obj
    );
    onOptionChange(newSelectedActions);
  };

  const updateSelectedBooleanOptions = (id: string, checked: boolean) => {
    const newSelectedActions = selectedOptions.map((obj) =>
      obj.id === id
        ? {
            id,
            optionId: checked ? "true" : "false",
          }
        : obj
    );
    onOptionChange(newSelectedActions);
  };

  return (
    <>
      {title && (
        <div className="mb-4 leading-none" data-testid="chart-actions-title">
          {title}
        </div>
      )}
      <div className="flex flex-col space-y-2">
        {actions.map((action) => {
          const {
            id,
            title: optionTitle,
            options,
            secondaryOptions,
            type,
            className,
            onUpdate,
          } = action;

          const selectedActionName = getSelectedChartActionOptionName(
            selectedOptions,
            action
          );

          if (type === "dropdownSelect") {
            return (
              <div
                key={id + optionTitle}
                className={classNames(
                  "inline-flex items-center space-x-2",
                  className
                )}
              >
                <span className="flex-1 text-nowrap">{optionTitle}:</span>
                <DropdownSelect
                  label={
                    selectedActionName
                      ? selectedActionName.toString()
                      : undefined
                  }
                  options={options}
                  onSelect={(selectedOption) =>
                    updateSelectedDropdownOptions(
                      id,
                      selectedOption.id as string
                    )
                  }
                  testId={`chart-action-dropdown-${id}`}
                />
              </div>
            );
          }
          if (type === "label") {
            return (
              <div
                className="inline-flex text-sm text-gray-400"
                data-testid={`chart-action-label-${id}`}
                key={id + optionTitle}
              >
                {optionTitle}
              </div>
            );
          }
          if (type === "attrNameValue") {
            return (
              <ChartActionAttributeNameValue
                attributeNames={options ? options : []}
                attributeValues={secondaryOptions ? secondaryOptions : []}
                key={optionTitle}
                onUpdate={onUpdate}
              />
            );
          }
          if (type === "boolean") {
            return (
              <Checkbox
                checked={
                  selectedOptions?.find((o) => o.id === id)?.optionId === "true"
                }
                onChange={(checked) =>
                  updateSelectedBooleanOptions(id, checked)
                }
                label={optionTitle}
              />
            );
          }
          if (type === "booleanAndDropdownSelect") {
            return (
              <BooleanAndDropdownSelect
                action={action}
                options={options}
                selectedOptions={selectedOptions}
                onOptionChange={onOptionChange}
                className={className}
              />
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default ChartActions;
