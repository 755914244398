import { Handlers } from "duck/agents/ClaimAnalytics/types";
import { DynamicTool, tool } from "@langchain/core/tools";

import { ASSOCIATED_VEHICLES_TAB_KEY } from "pages/constants";

const getGoToAssociatedVehiclesTabTool = (
  changeTabHandler: Handlers["changeTab"]
): DynamicTool => {
  const goToAssociatedVehiclesTab = () => {
    console.debug("Navigating to Associated Vehicles tab");
    changeTabHandler(ASSOCIATED_VEHICLES_TAB_KEY);
    return "success";
  };

  return tool(goToAssociatedVehiclesTab, {
    name: "goToAssociatedVehiclesTab",
    description:
      "Call this tool to navigate to queue a navigation to the Associated Vehicles tab action",
  });
};

export default getGoToAssociatedVehiclesTabTool;
