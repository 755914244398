import { Dispatch, SetStateAction } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_TITLE,
  BY_VEHICLE_AGE_TAB_TITLE,
  BY_VEHICLES_AGE_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_TITLE,
} from "pages/constants";
import { ByVehicleAgeData } from "pages/types";

import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { UseFilterSortState } from "features/ui/Filters/types";
import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import { ASSOCIATED_CLAIMS_TAB_KEY, SIGNAL_EVENTS_TAB_KEY } from "./constants";
import AssociatedClaims from "./tabPages/AssociatedClaims";
import AssociatedSignalEvents from "./tabPages/AssociatedSignalEvents/AssociatedSignalEvents";
import AssociatedVehicles from "./tabPages/AssociatedVehicles";
import ByVehicleAge from "./tabPages/ByVehicleAge/ByVehicleAge";
import SignalEvents from "./tabPages/SignalEvents/SignalEvents";
import TopContributors from "./tabPages/TopContributors/TopContributors";

export interface SignalEventsFiltersProps {
  signalEventsFilters?: FilterGroupState;
  vehiclesFilters?: FilterGroupState;
  onBadRequest: () => void;
  pageKey?: string;
}

export interface SignalEventsByVehicleAgeChartProps
  extends SignalEventsFiltersProps {
  byVehicleAgeData: ByVehicleAgeData;
  actions: ChartAction[];
  selectedOptions: SelectedChartOptions[];
  setSelectedOptions: Dispatch<SetStateAction<SelectedChartOptions[]>>;
}

export interface SignalEventsByVehicleAgeTableProps
  extends SignalEventsFiltersProps {
  byVehicleAgeData: ByVehicleAgeData;
}

export interface SignalEventsAnalyticsProps {
  signalEventsFiltersFilterSortState?: UseFilterSortState;
  vehiclesFiltersFilterSortState?: UseFilterSortState;
}

export interface SignalEventsAnalyticsTabsProps
  extends SignalEventsFiltersProps,
    SignalEventsAnalyticsProps {
  queryParamsToPersist?: string[];
}

const SignalEventsAnalyticsTabs = ({
  signalEventsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
  queryParamsToPersist,
  onBadRequest,
}: SignalEventsAnalyticsTabsProps) => {
  const { seaAssociatedSignalEvents } = useFlags();
  const signalEventsFilters = signalEventsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  const tabsItems: (Tab | boolean | undefined)[] = [
    {
      key: SIGNAL_EVENTS_TAB_KEY,
      title: "Signal Events",
      content: (
        <SignalEvents
          signalEventsFilters={signalEventsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: BY_VEHICLES_AGE_TAB_KEY,
      title: BY_VEHICLE_AGE_TAB_TITLE,
      content: (
        <ByVehicleAge
          signalEventsFilters={signalEventsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: TOP_CONTRIBUTORS_TAB_KEY,
      title: TOP_CONTRIBUTORS_TAB_TITLE,
      content: (
        <TopContributors
          signalEventsFiltersFilterSortState={
            signalEventsFiltersFilterSortState
          }
          vehiclesFiltersFilterSortState={vehiclesFiltersFilterSortState}
        />
      ),
    },
    {
      key: ASSOCIATED_VEHICLES_TAB_KEY,
      title: ASSOCIATED_VEHICLES_TAB_TITLE,
      content: (
        <AssociatedVehicles
          signalEventsFilters={signalEventsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: ASSOCIATED_CLAIMS_TAB_KEY,
      title: "Associated Claims",
      content: (
        <AssociatedClaims
          signalEventsFiltersFilterSortState={
            signalEventsFiltersFilterSortState
          }
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    seaAssociatedSignalEvents && {
      key: ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
      title: "Associated Signal Events",
      content: (
        <AssociatedSignalEvents
          signalEventsFiltersFilterSortState={
            signalEventsFiltersFilterSortState
          }
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return (
    <StatefulTabs tabs={tabs} queryParamsToPersist={queryParamsToPersist} />
  );
};

export default SignalEventsAnalyticsTabs;
