import {
  LC_API_KEY,
  LC_ENDPOINT,
} from "duck/agents/ClaimAnalytics/agent/constants";
import * as hub from "langchain/hub";
import { ChatPromptTemplate } from "@langchain/core/prompts";

// TODO: this does not work because hub is not finding openAIApiKey
//    I susspect because process.env.OPENAI_API_KEY is undefined and can't be set
// This relates to https://viaduct-ai.atlassian.net/browse/DUCK-68

// export interface ModelSpec {
//   modelName: string;
//   temperature: number;
//   modelKwargs?: any;
// }

// export const getModelSpec = async (
//   lcApiKey?: string,
//   lcApiUrl?: string
// ): Promise<ModelSpec> => {

//   const prompt = await hub.pull("claim_analytics_expert", {
//     apiKey: lcApiKey,
//     apiUrl: lcApiUrl,
//     includeModel: true,
//   });

//   console.log(prompt);

//   const modelName = prompt.lc_kwargs.last.lc_kwargs.bound.lc_kwargs.modelName;
//   const temperature =
//     prompt.lc_kwargs.last.lc_kwargs.bound.lc_kwargs.temperature;
//   const modelKwargs = prompt.lc_kwargs.last.lc_kwargs.kwargs;

//   if (!modelName || !temperature) {
//     throw new Error("Failed to get model spec");
//   }

//   return { modelName, temperature, modelKwargs };
// };

// Cache the prompts so that we only retrieve them once.
// The cache will be cleared when the page is reloaded.
let claimAnalyticsPrompt: ChatPromptTemplate;
let validatePrompt: ChatPromptTemplate;
let rejectPrompt: ChatPromptTemplate;
let clarifyPrompt: ChatPromptTemplate;

export const getClaimAnalyticsPrompt = async (
  lcApiKey?: string,
  lcApiUrl?: string
): Promise<ChatPromptTemplate> => {
  if (claimAnalyticsPrompt) {
    return claimAnalyticsPrompt;
  }

  claimAnalyticsPrompt = await hub.pull<ChatPromptTemplate>(
    "claim_analytics_expert_v2",
    {
      apiKey: lcApiKey,
      apiUrl: lcApiUrl,
      includeModel: false,
    }
  );

  return claimAnalyticsPrompt;
};

export const getValidatePrompt = async (
  contextPrompt: ChatPromptTemplate,
  options: string[],
  lcApiKey?: string,
  lcApiUrl?: string
): Promise<ChatPromptTemplate> => {
  if (validatePrompt) {
    return validatePrompt;
  }

  const initialPrompt = await hub.pull<ChatPromptTemplate>(
    "claim_analytics_validate",
    {
      apiKey: lcApiKey,
      apiUrl: lcApiUrl,
    }
  );

  const intermediatePrompt = await initialPrompt.partial({
    options: options.join(","),
  });

  validatePrompt = ChatPromptTemplate.fromMessages([
    contextPrompt,
    intermediatePrompt,
  ]);

  return validatePrompt;
};

export const getRejectPrompt = async (
  contextPrompt: ChatPromptTemplate,
  lcApiKey?: string,
  lcApiUrl?: string
): Promise<ChatPromptTemplate> => {
  if (rejectPrompt) {
    return rejectPrompt;
  }

  const intermediatePrompt = await hub.pull<ChatPromptTemplate>(
    "claim_analytics_reject_v2",
    {
      apiKey: lcApiKey,
      apiUrl: lcApiUrl,
    }
  );
  rejectPrompt = ChatPromptTemplate.fromMessages([
    contextPrompt,
    intermediatePrompt,
  ]);

  return rejectPrompt;
};

export const getClarifyPrompt = async (
  contextPrompt: ChatPromptTemplate,
  lcApiKey?: string,
  lcApiUrl?: string
): Promise<ChatPromptTemplate> => {
  if (clarifyPrompt) {
    return clarifyPrompt;
  }

  const intermediatePrompt = await hub.pull<ChatPromptTemplate>(
    "claim_analytics_clarify_v2",
    {
      apiKey: lcApiKey,
      apiUrl: lcApiUrl,
    }
  );
  clarifyPrompt = ChatPromptTemplate.fromMessages([
    contextPrompt,
    intermediatePrompt,
  ]);

  return clarifyPrompt;
};

type AllPrompts = {
  claimAnalyticsPrompt: ChatPromptTemplate;
  validatePrompt: ChatPromptTemplate;
  rejectPrompt: ChatPromptTemplate;
  clarifyPrompt: ChatPromptTemplate;
};

const getPrompts = async (
  validateOptions: string[] = ["agent", "reject", "clarify"]
): Promise<AllPrompts> => {
  const claimAnalyticsPrompt = await getClaimAnalyticsPrompt(
    LC_API_KEY,
    LC_ENDPOINT
  );

  const [validatePrompt, rejectPrompt, clarifyPrompt] = await Promise.all([
    getValidatePrompt(
      claimAnalyticsPrompt,
      validateOptions,
      LC_API_KEY,
      LC_ENDPOINT
    ),
    getRejectPrompt(claimAnalyticsPrompt, LC_API_KEY, LC_ENDPOINT),
    getClarifyPrompt(claimAnalyticsPrompt, LC_API_KEY, LC_ENDPOINT),
  ]);

  return {
    claimAnalyticsPrompt,
    validatePrompt,
    rejectPrompt,
    clarifyPrompt,
  };
};

export default getPrompts;
