import CustomEventPreview from "pages/SignalEventStudio/Common/CustomEventPreview";
import {
  getCustomSignalEventRequestParams,
  SignalEventStudioState,
} from "pages/SignalEventStudio/utils";

import VINSelectSection from "./VINSelectSection";

interface Props {
  signalEventStudioState: SignalEventStudioState;
}

const SignalEventPreview = ({ signalEventStudioState }: Props) => {
  const {
    schema,
    customAttributes,
    filterBuilderState,
    selectedEventTypeOption,
    customAttributeFilterBuilderState,
    selectedDownsamplingOption,
    selectedVIN,
    setSelectedVIN,
    editMode,
    setEditMode,
    isValidForm,
    showPreview,
  } = signalEventStudioState;
  return (
    <>
      {isValidForm && (
        <VINSelectSection
          selectedVIN={selectedVIN}
          setSelectedVIN={setSelectedVIN}
          setEditMode={setEditMode}
          editMode={editMode}
        />
      )}

      {showPreview && (
        <CustomEventPreview
          schema={schema}
          customAttributes={customAttributes}
          // we check for selectedVIN existence in showPreview already
          selectedVIN={selectedVIN!}
          requestBody={getCustomSignalEventRequestParams(
            [selectedVIN!],
            selectedEventTypeOption,
            filterBuilderState,
            customAttributeFilterBuilderState,
            customAttributes,
            selectedDownsamplingOption
          )}
        />
      )}
    </>
  );
};

export default SignalEventPreview;
