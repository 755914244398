import { Dispatch, SetStateAction, useContext } from "react";
import { DuckMessagesContext } from "duck/context/DuckMessagesContextWrapper";
import { GiFairyWand } from "react-icons/gi";
import { Button, Stack } from "@mui/material";

import {
  DUCK_ACTION_DISCARDED_MESSAGE,
  DUCK_ACTION_PERFORMED_MESSAGE,
  DUCK_DO_IT_FOR_ME,
} from "./constants";
import { useUpdateLocation } from "./hooks";

interface DuckPendingActionAvailableProps {
  setPendingAction: Dispatch<SetStateAction<boolean>>;
}

const DuckPendingActionAvailable = ({
  setPendingAction,
}: DuckPendingActionAvailableProps) => {
  const { addMessage } = useContext(DuckMessagesContext);
  const updateLocation = useUpdateLocation();

  const handleDoItClick = () => {
    updateLocation();
    setPendingAction(false);
    addMessage(DUCK_ACTION_PERFORMED_MESSAGE);
  };

  const handleNoThanksClick = () => {
    setPendingAction(false);
    addMessage(DUCK_ACTION_DISCARDED_MESSAGE);
  };

  return (
    <Stack alignItems="center" direction="column">
      <Button
        variant="contained"
        color="primary"
        onClick={handleDoItClick}
        size="small"
      >
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <GiFairyWand />
          <div>{DUCK_DO_IT_FOR_ME}</div>
        </Stack>
      </Button>

      <Button
        variant="text"
        color="secondary"
        size="small"
        onClick={handleNoThanksClick}
      >
        No thanks
      </Button>
    </Stack>
  );
};

export default DuckPendingActionAvailable;
