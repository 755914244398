import { useEffect, useState } from "react";
import classNames from "classnames";

import { SHORT_DATE_FORMAT } from "shared/constants";
import {
  camelCaseToTitle,
  formatDate,
  formatNumber,
  getTenantMileageUnit,
} from "shared/utils";

import { GENERIC_DATA_TO_DISPLAY, GENERIC_JSON_DATA_KEY } from "./constants";
import { RecordProps } from "./EventDetail";
import InformationWrapper from "./InformationWrapper";
import { getEntityFunc } from "./utils";

const GenericDetail = ({ serviceRecord, onClose }: RecordProps) => {
  const getEntity = getEntityFunc(serviceRecord);

  const [entityData, setEntityData] = useState<Record<string, any>>();
  const mileageUnit = getTenantMileageUnit();

  useEffect(() => {
    if (!getEntity) return;

    getEntity({
      ID: serviceRecord.ID,
      mileageUnit,
    }).then((res) => {
      setEntityData(res.data);
    });
  }, [getEntity, serviceRecord.ID, mileageUnit]);

  const customAgeFields = [
    "monthsInService",
    "daysInService",
    "totalEngineHours",
    "mileage",
  ]; // all of these fields are of type number

  return (
    <InformationWrapper
      externalID={serviceRecord.externalID || ""}
      onClose={onClose}
    >
      {GENERIC_DATA_TO_DISPLAY.map(({ accessor, type }, index) => {
        let value = "";
        if (serviceRecord[accessor]) {
          value = serviceRecord[accessor] as string;
        } else {
          return null;
        }

        if (type === "date") {
          value = formatDate(value as string, SHORT_DATE_FORMAT, true);
        }

        return (
          <div
            className={classNames("grid grid-cols-[180px_auto] gap-x-3 py-2", {
              "border-t": index > 0,
            })}
          >
            <span className="text-gray-400 capitalize">
              {camelCaseToTitle(accessor)}
            </span>
            <span className="break-words">
              {(type === "date" &&
                formatDate(value as string, SHORT_DATE_FORMAT, true)) ||
                (value as string)}
            </span>
          </div>
        );
      })}
      {entityData &&
        customAgeFields.map((accessor: string) => {
          if (!entityData[accessor]) {
            return null;
          }

          const value = entityData[accessor];
          let valueToShow: string = value.toString();
          if (accessor === "mileage") {
            valueToShow = `${formatNumber(value, 2)?.toString()} ${mileageUnit}`;
          }

          return (
            <div
              className={classNames(
                "grid grid-cols-[180px_auto] gap-x-3 py-2 border-t"
              )}
            >
              <span className="text-gray-400 capitalize">
                {camelCaseToTitle(accessor)}
              </span>
              <span className="break-words">{valueToShow}</span>
            </div>
          );
        })}
      {entityData &&
        Object.keys(entityData[GENERIC_JSON_DATA_KEY]).map((key) => (
          <div
            key={key}
            className="grid grid-cols-[180px_auto] gap-x-3 py-2 border-t"
          >
            <span className="text-gray-400">{camelCaseToTitle(key)}</span>
            <span className="break-words">
              {entityData[GENERIC_JSON_DATA_KEY][key]}
            </span>
          </div>
        ))}
    </InformationWrapper>
  );
};

export default GenericDetail;
