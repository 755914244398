import { useMemo, useState } from "react";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ClaimAssociatedSignalEvent,
  ClaimAssociatedSignalEventsRequest,
} from "shared/api/claims/api";
import { useListClaimsAssociatedSignalEvents } from "shared/api/claims/hooks";
import { getSortFilter } from "shared/api/utils";
import { SIGNAL_EVENTS_GENERIC_FILTER } from "shared/filterDefinitions";
import { useCustomLocalStorageState } from "shared/hooks";
import { SortBy } from "shared/types";
import { pluralize } from "shared/utils";

import { ClaimAnalyticsTabsProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import { SIGNAL_EVENTS_PAGE_KEY } from "pages/SignalEventsAnalytics/constants";
import { VEHICLES_PAGE_KEY } from "pages/Vehicles/constants";

import APIError from "features/ui/APIError";
import { getCheckboxCheckedProps } from "features/ui/Checkbox/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { DEFAULT_RELATES_FILTER } from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import { WINDOW_DIRECTION_OPTION_BEFORE } from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesTimeWindowForm";
import SelectedRowsActions from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/SelectedRowsActions";
import RelatedFilterRow from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterRow";
import { isRelatesFilterOperatorIsNotFiltered } from "features/ui/Filters/FilterTypes/RelatesFilter/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterOperator, RelatesFilterState } from "features/ui/Filters/types";
import { getFiltersKey } from "features/ui/Filters/utils";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { Selectable } from "features/ui/Table/Table";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCellWithCheckbox from "features/ui/Table/TableCellWithCheckbox";

import { routes } from "services/routes";
import * as config from "config/config";

const PAGE_KEY = "claimAnalytics-associated-se";
const DEFAULT_SORT: SortBy = { associationStrength: "desc" };
const ROWS_PER_PAGE = 20;

export const RELATES_FILTER_KEY = "claims-associated-se-relates-filter-v2";

export const getAssociatedSignalEventsSchema = (
  selectedPeriod: string,
  selectableOptions?: Selectable
): SchemaEntry[] => {
  return [
    {
      label: "Associated Signal Event",
      accessor: "signalEventID",
      dataType: DataType.JSX,
      sortable: true,
      filter: SIGNAL_EVENTS_GENERIC_FILTER({
        label: "Associated Signal Event",
        fieldName: "signalEventID",
        search: true,
        filterType: "string",
        fieldNameForAPI: "ID",
        disableFiltering: true,
      }),
      selectable: selectableOptions,
    },
    {
      label: "Description",
      accessor: "signalEventDescription",
      dataType: DataType.STRING,
      limitedWidthClass: "max-w-xs",
    },
    {
      label: "Claim rate",
      accessor: "IPTV",
      dataType: DataType.NUMBER,
      sortable: true,
      description: `Claim rate per 1000 vehicles experiencing this signal event within ${selectedPeriod} of its occurrence`,
    },
    {
      label: "Association strength",
      accessor: "associationStrength",
      dataType: DataType.NUMBER,
      sortable: true,
      description: (
        <div className="text-left">
          A measure of the association between the signal events and the set of
          claims. In particular, the association strength shows how many times
          more likely a vehicle which experiences the signal event is to go on
          to eventually have a claim than the average vehicle in the population
          (for example, association strength higher than 1 indicates that
          vehicles are more likely to experience the failure if they’ve
          experienced the signal event). A higher association strength indicates
          a higher likelihood of a non-random relationship between the signal
          event and the set of claims. Empty cell indicates a failure without
          sufficient evidence of correlation.
        </div>
      ),
    },
    {
      label: "Claims w/ preceding SE",
      accessor: "percentClaimsWithPreceding30DaysSignalEvent",
      dataType: DataType.PERCENTAGE,
      description: `Percent of claims with preceding signal event within ${selectedPeriod}`,
      sortable: true,
    },

    {
      label: "Total signal event occurrences",
      accessor: "totalEventOccurrences",
      dataType: DataType.NUMBER,
      description: `Total number of signal event occurrences within ${selectedPeriod} prior to the defined claim set`,
      sortable: true,
      filter: SIGNAL_EVENTS_GENERIC_FILTER({
        label: "Total signal event occurrences",
        fieldName: "totalEventOccurrences",
        filterType: "number",
        disableSelectFilters: true,
        onlyAllowPositiveIntegers: true,
      }),
    },
    {
      label: "Associated Claims",
      accessor: "numAssociatedClaims",
      dataType: DataType.NUMBER,
      description: `The total number of claims that occur up to ${selectedPeriod} after a signal event`,
      sortable: true,
      filter: SIGNAL_EVENTS_GENERIC_FILTER({
        label: "Associated Claims",
        fieldName: "numAssociatedClaims",
        filterType: "number",
        disableSelectFilters: true,
        onlyAllowPositiveIntegers: true,
      }),
    },
    {
      label: "Associated Vehicles",
      accessor: "numAssociatedVehicles",
      dataType: DataType.NUMBER,
      description: `The total number of unique vehicles with at least one claim occurring up to ${selectedPeriod} after a signal event.`,
      sortable: true,
      filter: SIGNAL_EVENTS_GENERIC_FILTER({
        label: "Associated Vehicles",
        fieldName: "numAssociatedVehicles",
        filterType: "number",
        disableSelectFilters: true,
        onlyAllowPositiveIntegers: true,
      }),
    },
  ];
};

const formatRow = (
  row: ClaimAssociatedSignalEvent,
  selectedSignalEvents: Set<string>,
  setSelectedSignalEvents: (events: Set<string>) => void
) => {
  return {
    ...row,
    signalEventID: (
      <TableCellWithCheckbox
        value={row.signalEventID}
        selectedValues={selectedSignalEvents}
        setSelectedValues={setSelectedSignalEvents}
        testId="checkbox-associated-signal-event"
      />
    ),
  };
};

const AssociatedSignalEvents = ({
  claimsFiltersFilterSortState,
  vehiclesFilters,
  onBadRequest,
}: ClaimAnalyticsTabsProps) => {
  const navigate = useNavigate();

  const {
    pages: { signalEventsAnalytics },
  } = config.get();

  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);
  const signalEventsFilterKey = getFiltersKey(SIGNAL_EVENTS_PAGE_KEY);

  const defaultSignalEventFilters = filterStateToFilterGroupState(
    signalEventsAnalytics?.defaultSignalEventFilters
  );

  const defaultAppliedFilters: RelatesFilterState = defaultSignalEventFilters
    ? {
        ...DEFAULT_RELATES_FILTER,
        filters: defaultSignalEventFilters,
      }
    : DEFAULT_RELATES_FILTER;

  const [appliedRelatesFilter, setAppliedRelatesFilter] =
    useCustomLocalStorageState<RelatesFilterState>(RELATES_FILTER_KEY, {
      defaultValue: defaultAppliedFilters,
    });
  const [relatesFilter, setRelatesFilter] =
    useState<RelatesFilterState>(appliedRelatesFilter);

  const [selectedSignalEvents, setSelectedSignalEvents] = useState(
    new Set<string>()
  );

  const claimsFilters = claimsFiltersFilterSortState?.filters;

  const {
    manageOnFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const windowSize = isRelatesFilterOperatorIsNotFiltered(appliedRelatesFilter)
    ? parseInt(appliedRelatesFilter.options.windowSize.toString())
    : undefined;

  const requestParams: ClaimAssociatedSignalEventsRequest = {
    sort: getSortFilter(sort),
    filter: getFiltersQuery(filters),
    claimsFilter: getFiltersQuery(claimsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    signalEventOccurrencesFilter: getFiltersQuery(appliedRelatesFilter.filters),
    limit: ROWS_PER_PAGE,
    signalEventsTimeWindow: windowSize,
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListClaimsAssociatedSignalEvents(requestParams);

  const allSelectableValues = data?.map((x) => x.signalEventID) || [];
  const { allChecked, indeterminateChecked } = getCheckboxCheckedProps(
    selectedSignalEvents,
    allSelectableValues
  );

  const toggleSelectedSignalEvents = () => {
    if (allChecked) {
      setSelectedSignalEvents(new Set<string>());
      return;
    }
    setSelectedSignalEvents(new Set<string>(allSelectableValues));
  };

  const selectedPeriod = `${windowSize} ${pluralize("day", windowSize)}`;

  const schema = getAssociatedSignalEventsSchema(selectedPeriod, {
    onClick: toggleSelectedSignalEvents,
    checked: allChecked,
    indeterminate: indeterminateChecked,
  });

  const formattedData = useMemo(
    () =>
      data?.map((row) =>
        formatRow(row, selectedSignalEvents, setSelectedSignalEvents)
      ),
    [data, selectedSignalEvents]
  );

  const addAsRelatedSignalEvent = () => {
    setSelectedSignalEvents(new Set<string>());
    toast.success("Claim Filters updated");
  };

  const onUpdateRelatesFilter = (row: RelatesFilterState) => {
    setRelatesFilter(row);
  };

  const onCancel = () => {
    setRelatesFilter(DEFAULT_RELATES_FILTER);
    setAppliedRelatesFilter(DEFAULT_RELATES_FILTER);
  };

  const onApply = () => {
    setAppliedRelatesFilter(relatesFilter);
  };

  const navigateToSignalEventAnalytics = () => {
    const signalEventIDFilter: FilterGroupState = filterStateToFilterGroupState(
      {
        signalEventID: {
          operator: FilterOperator.IN,
          values: Array.from(selectedSignalEvents),
        },
      }
    );

    navigate({
      pathname: routes.signalEventAnalytics,
      search: qs.stringify({
        [vehicleFilterKey]: getFiltersQuery(vehiclesFilters),
        [signalEventsFilterKey]: getFiltersQuery(signalEventIDFilter),
      }),
    });
  };

  return (
    <>
      <RelatedFilterRow
        relatesState={relatesFilter}
        appliedRelatesFilter={appliedRelatesFilter}
        inFilterSelector={false}
        baseEntityText="Claim's Repair Date"
        onUpdate={onUpdateRelatesFilter}
        onApply={onApply}
        onCancel={onCancel}
        windowDirectionOptions={[WINDOW_DIRECTION_OPTION_BEFORE]}
      />
      <SelectedRowsActions
        filterSortState={claimsFiltersFilterSortState}
        relatesFilter={appliedRelatesFilter}
        selectedSignalEvents={selectedSignalEvents}
        onAddToFilter={addAsRelatedSignalEvent}
        onExploreInSEAnalyticsActionClick={navigateToSignalEventAnalytics}
      />
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageOnFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
          testId="associated-signal-events-table"
        />
      )}
      {error && (
        <APIError
          error={error}
          onBadRequest={() => {
            resetFilterSortState();
            onBadRequest();
          }}
        />
      )}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default AssociatedSignalEvents;
