import { useContext } from "react";
import classNames from "classnames";
import { DuckMessagesContext } from "duck/context/DuckMessagesContextWrapper";
import { BsChatRight as ResetChatIcon } from "react-icons/bs";
import { IconButton } from "@mui/material";

interface DuckResetMemoryProps {
  enabled: boolean;
  resetThreadId: () => void;
}

const DuckResetMemory = ({ enabled, resetThreadId }: DuckResetMemoryProps) => {
  const { clearMessages } = useContext(DuckMessagesContext);

  const handleResetMemory = () => {
    if (enabled) {
      resetThreadId();
      clearMessages();
    }
  };

  // It was not possible to style the IconButton with the Tailwind className syntax,
  // leading to this sx jungle. The IconButton has advantages, like a disabled state
  // with nice visual treatment, so this ugly styling syntax seems to be worth it.
  // General takeaway: don't try to use Tailwind to style MUI components.
  return (
    <IconButton
      aria-label="reset memory"
      sx={{
        bgcolor: "white",
        ":hover": { bgcolor: "white" },
        borderRadius: "0.375rem",
        "&.Mui-disabled": {
          opacity: 0.8,
          bgcolor: "white",
        },
        height: "32px",
        width: "32px",
      }}
      disabled={!enabled}
      onClick={handleResetMemory}
    >
      <ResetChatIcon
        size={18}
        className={classNames({
          "text-black": enabled,
          "text-gray-400": !enabled,
        })}
      />
      <span
        className={classNames("absolute text-sm font-bold", {
          "text-black": enabled,
          "text-gray-400": !enabled,
        })}
        style={{
          top: "4px",
          right: "12px",
        }}
      >
        +
      </span>
    </IconButton>
  );
};

export default DuckResetMemory;
