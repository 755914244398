import { useEffect } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FormControlLabel, Switch } from "@mui/material";

import { CustomSignalEventDefinition } from "shared/api/customSignalEvents/api";
import { formatAPIDate } from "shared/api/utils";
import { useCustomLocalStorageState } from "shared/hooks";

import { getCustomSignalEventState } from "pages/CustomSignalEvents/utils";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { filterStateToFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterOperator, FilterState } from "features/ui/Filters/types";
import StatefulTabs, { Tab } from "features/ui/StatefulTabs";
import Title from "features/ui/Title";

import SignalEventDescription from "./Common/SignalEventDescription";
import { PAGE_TITLE } from "./constants";
import SignalEventDefinition from "./Definition/SignalEventDefinition";
import { usePlaygroundFormState } from "./hooks";
import SignalEventOverview from "./Legacy/SignalEventOverview";
import SignalEventPreview from "./Legacy/SignalEventPreview";
import SignalEventPreviewOccurrences from "./Preview/SignalEventPreviewOccurrences";

interface Props {
  pageTitle?: string;
  customSignalEventDefinition?: CustomSignalEventDefinition;
  requestKey?: string;
}

const SPLIT_SCREEN_KEY = "custom_signal_event_split_screen";
const DEFINITION_TITLE = "Definition";
const PREVIEW_TITLE = "Preview Occurrences In Vehicles";

const SignalEventStudio = ({
  pageTitle,
  customSignalEventDefinition,
  requestKey,
}: Props) => {
  const [splitScreen, setSplitScreen] = useCustomLocalStorageState(
    SPLIT_SCREEN_KEY,
    {
      defaultValue: false,
    }
  );

  const signalEventStudioState = usePlaygroundFormState(
    customSignalEventDefinition
  );
  const {
    editMode,
    isValidForm,
    selectedEventTypeOption,
    dateParam,
    dateType,
  } = signalEventStudioState;

  const { customSignalEvents } = useFlags();

  const date = new Date();
  const aMonthAgo = new Date(date);
  aMonthAgo.setMonth(date.getMonth() - 1);

  // page key for date filters should change based on event type, since date type might be different for event type
  const dateFilterPageKey = `custom_signal_events_date_filter_${selectedEventTypeOption?.id}`;
  const defaultDateFilter: FilterGroupState = filterStateToFilterGroupState({
    [dateParam]: {
      values: [
        formatAPIDate(aMonthAgo.toString(), dateType),
        formatAPIDate(new Date().toString(), dateType),
      ],
      operator: FilterOperator.BETWEEN,
    },
  } as FilterState);

  const dateFilterSortState = useFilterSortState({
    pageKey: dateFilterPageKey,
    defaultFilterValues: defaultDateFilter,
    disableUsingQuery: true,
  });

  useEffect(() => {
    dateFilterSortState.resetFilterSortState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilterPageKey]);

  const {
    actionsState: { canEditDefinition },
  } = getCustomSignalEventState(customSignalEventDefinition?.status, editMode);

  const tabsItems: (Tab | undefined | boolean)[] = [
    {
      key: "custom_signal_events_definition_tab",
      title: DEFINITION_TITLE,
      content: (
        <SignalEventDefinition
          signalEventStudioState={signalEventStudioState}
          canEditDefinition={canEditDefinition}
        />
      ),
    },
    customSignalEvents && {
      key: "custom_signal_events_preview_tab",
      title: PREVIEW_TITLE,
      content: (
        <SignalEventPreviewOccurrences
          signalEventStudioState={signalEventStudioState}
          dateFilterSortState={dateFilterSortState}
        />
      ),
      disabled: !isValidForm,
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return (
    <>
      <div className="relative">
        <Title text={pageTitle || PAGE_TITLE} />
        {customSignalEvents && (
          <SignalEventOverview
            signalEventStudioState={signalEventStudioState}
            customSignalEventDefinition={customSignalEventDefinition}
            requestKey={requestKey}
          />
        )}
        {!customSignalEvents && <SignalEventDescription />}
      </div>
      {customSignalEvents && (
        <FormControlLabel
          control={
            <Switch
              checked={splitScreen}
              onChange={() => setSplitScreen(!splitScreen)}
              size="small"
            />
          }
          label={`Show both ${DEFINITION_TITLE} and ${PREVIEW_TITLE} on the same page`}
        />
      )}

      {(!splitScreen || !customSignalEvents) && (
        <StatefulTabs tabs={tabs} disableUsingQuery={true} />
      )}
      {splitScreen && customSignalEvents && (
        <div className="flex flex-wrap">
          <div className="3xl:w-full 2xl:w-1/2">
            <div className="text-xl font-semibold mb-2">{DEFINITION_TITLE}</div>
            <SignalEventDefinition
              signalEventStudioState={signalEventStudioState}
              canEditDefinition={canEditDefinition}
              splitScreen={splitScreen}
              className="!w-[98%] !max-w-[65rem]"
            />
          </div>
          <div className="3xl:w-full 2xl:w-1/2">
            <div className="text-xl font-semibold mb-2">{PREVIEW_TITLE}</div>
            <SignalEventPreviewOccurrences
              signalEventStudioState={signalEventStudioState}
              dateFilterSortState={dateFilterSortState}
              splitScreen={splitScreen}
              className="!w-[98%] !max-w-[65rem]"
            />
          </div>
        </div>
      )}

      {!customSignalEvents && (
        <SignalEventPreview signalEventStudioState={signalEventStudioState} />
      )}
    </>
  );
};
export default SignalEventStudio;
