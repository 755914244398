import { GraphStateType } from "duck/agents/common/utils/state";
import { StructuredTool } from "@langchain/core/tools";
import { ToolNode } from "@langchain/langgraph/prebuilt";

/**
 * @summary Node responsible for running tools and returning the results to the agent.
 * @param tools
 * @returns Tool node
 */
const getToolNode = (tools: StructuredTool[]): ToolNode<GraphStateType> =>
  new ToolNode<GraphStateType>(tools);

export default getToolNode;
