import { GraphStateType } from "duck/agents/common/utils/state";
import { ChatPromptTemplate } from "@langchain/core/prompts";
import { Runnable } from "@langchain/core/runnables";
import { StructuredTool } from "@langchain/core/tools";
import { ChatOpenAI, ChatOpenAICallOptions } from "@langchain/openai";

export type NodeOutputType = Partial<GraphStateType>;

export interface NodeType {
  (state: GraphStateType, config?: any): Promise<NodeOutputType>;
}

/**
 * @summary Provide a function to call the indicated model.
 * @param model The model to call
 * @returns A function that calls the indicated model. The response to that async
 * function call is an array of messages.
 */
export const callModel =
  (model: Runnable) =>
  async (state: GraphStateType, config: any = {}): Promise<NodeOutputType> => {
    const { messages, pageState } = state;
    return {
      messages: [
        await model.invoke(
          {
            messages,
            current_state: JSON.stringify(pageState),
          },
          config
        ),
      ],
    };
  };

/**
 * @summary Create and return the node responsible for running the agent.
 * @param llm The LLM agent that processes the utterance
 * @param tools The tools available to the LLM
 * @param prompt The prompt to send to the LLM
 * @param toolsArgs The arguments to pass to the tools
 * @returns The node responsible for running the agent.
 */
export const createAgentNode = (
  llm: ChatOpenAI<ChatOpenAICallOptions>,
  tools: StructuredTool[],
  prompt: ChatPromptTemplate,
  toolsArgs?: Record<string, any>
): NodeType => {
  const model = prompt.pipe(llm.bindTools(tools, toolsArgs));

  return callModel(model);
};
