import { APIErrorResponse, CountResponse } from "shared/api/api";
import { CustomSignalEventsRequestBody } from "shared/api/customSignalEvents/api";
import {
  useListCustomSignalEvents,
  useListCustomSignalEventsCount,
} from "shared/api/customSignalEvents/hooks";
import { SignalEventOccurrencesVINAggregateBucket } from "shared/api/signalEvents/api";
import { APIFilter } from "shared/api/utils";

import { CustomAttribute } from "pages/SignalEventStudio/Definition/CustomAttributes/CustomAttributeSelect";
import CustomEventsTableSection from "pages/SignalEventStudio/Legacy/CustomEventsTableSection";
import { MAX_LIMIT_EVENTS } from "pages/VINView/constants";
import EventsTimeline from "pages/VINView/Events/EventsTimeline";

import { SchemaEntry } from "features/ui/Table";

import FormSection from "./FormSection";

interface Props {
  schema?: SchemaEntry[];
  customAttributes: CustomAttribute[];
  selectedVIN: string;
  requestBody: CustomSignalEventsRequestBody;
  className?: string;
}

const createCustomSignalEventEntriesFromCount = (
  data: CountResponse | undefined,
  isLoading: boolean,
  error: APIErrorResponse | undefined,
  vin: string
): SignalEventOccurrencesVINAggregateBucket[] => {
  if (error || isLoading || data?.count === undefined) {
    return [];
  }
  return [
    {
      signalEventID: "Custom",
      VIN: vin,
      totalOccurrences: data?.count,
      description: "Events defined on this page",
    },
  ];
};

const CustomEventPreview = ({
  schema,
  customAttributes,
  selectedVIN,
  requestBody,
  className,
}: Props) => {
  const {
    data: countData,
    isLoading: countIsLoading,
    error: countError,
  } = useListCustomSignalEventsCount({}, requestBody);

  const { data, isLoading, error } = useListCustomSignalEvents(
    { limit: MAX_LIMIT_EVENTS },
    requestBody
  );

  const timelineStaticFilters: APIFilter[] = [
    { name: "VIN", op: "eq", value: selectedVIN },
  ];

  const customEvents: SignalEventOccurrencesVINAggregateBucket[] =
    createCustomSignalEventEntriesFromCount(
      countData,
      countIsLoading,
      countError,
      selectedVIN
    );

  return (
    <>
      <CustomEventsTableSection
        baseEventTypeSchema={schema}
        customAttributes={customAttributes}
        data={data}
        dataIsLoading={isLoading}
        dataError={error}
        countIsLoading={countIsLoading}
        countError={countError}
        count={countData?.count}
        className={className}
      />
      <FormSection
        data-testId="vehicle-event-timeline"
        title="Timeline for Selected Vehicle"
        text="See your custom events in the context of other events for this vehicle"
        className={className}
      >
        <EventsTimeline
          vin={selectedVIN}
          staticFilters={timelineStaticFilters}
          customEventTableRows={customEvents}
          customEventsRequestBody={requestBody}
          customEventSchema={schema}
        />
      </FormSection>
    </>
  );
};

export default CustomEventPreview;
