import { Dispatch, SetStateAction, useContext, useEffect, useRef } from "react";
import {
  DuckMessagesContext,
  DuckMessage as DuckMessageType,
} from "duck/context/DuckMessagesContextWrapper";
import { Stack } from "@mui/material";

import DuckLoadingAnimation from "./DuckLoadingAnimation";
import DuckMessage from "./DuckMessage";
import DuckPendingActionAvailable from "./DuckPendingActionAvailable";

interface DuckMessagesProps {
  loading: boolean;
  pendingAction: boolean;
  setPendingAction: Dispatch<SetStateAction<boolean>>;
}

const DuckMessages = ({
  loading,
  pendingAction,
  setPendingAction,
}: DuckMessagesProps) => {
  const { messages } = useContext(DuckMessagesContext);

  // The scrollToBottom function uses this ref to ensure that the most recent
  // messages are visible.
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  /**
   * This effect ensures that the most recent messages are visible by scrolling to
   * the bottom of the messages container when new messages are added, or when the
   * loading state changes.
   */
  useEffect(() => {
    scrollToBottom();
  }, [messages, loading]);

  return (
    <Stack
      ref={messagesContainerRef}
      spacing={1}
      sx={{
        bgcolor: "white",
        width: "100%",
        flexGrow: 1,
        border: 1,
        borderColor: "grey.300",
        overflowY: "auto",
        padding: 1,
        height: "100%",
      }}
    >
      {messages.map((message: DuckMessageType, ix: number) => (
        <DuckMessage key={ix} message={message} />
      ))}

      {loading && <DuckLoadingAnimation />}

      {pendingAction && (
        <DuckPendingActionAvailable setPendingAction={setPendingAction} />
      )}
    </Stack>
  );
};

export default DuckMessages;
