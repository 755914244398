const SignalEventDescription = () => (
  <div className="text-gray-600 text-sm mb-4 max-w-4xl">
    Your custom signal event can be based on any event type. It is defined by
    the combination of filters on base event type attributes, and filters on
    derived attributes you create. You see how your custom signal performs by
    viewing it in the vehicle timeline for any vehicle you choose.
  </div>
);

export default SignalEventDescription;
