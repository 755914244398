import { useEffect, useState } from "react";

import { cloneObject } from "shared/utils";

import {
  FilterGroupState,
  FilterRowStateNotNull,
} from "features/ui/Filters/FilterBuilder/types";
import { FilterSkeleton } from "features/ui/Filters/FilterTypes/SelectFilter/FilterSkeleton";
import ResetToDefaultButton from "features/ui/Filters/FilterWizard/ResetToDefaultButton";
import {
  FilterOperator,
  onFilterChangeCallback,
  RelatesFilterState,
} from "features/ui/Filters/types";

import { DEFAULT_RELATES_FILTER } from "./constants";
import RelatedFilterRow from "./RelatesFilterRow";
import { encodeRelatesFilter } from "./utils";

interface Props {
  filters?: FilterGroupState;
  defaultFilters?: FilterGroupState;
  filter?: FilterRowStateNotNull;
  fieldName: string;
  onFiltersReset?: (accessors?: string[]) => void;
  onFilterChange: onFilterChangeCallback;
  initialized?: boolean;
  baseEntityText?: string;
  disableSelectingWindowDirection?: boolean;
}

const RelatesFilter = ({
  filters,
  defaultFilters,
  filter,
  fieldName,
  onFiltersReset,
  onFilterChange,
  initialized,
  baseEntityText = "Claim",
  disableSelectingWindowDirection = false,
}: Props) => {
  const [relatesFilter, setRelatesFilter] = useState<RelatesFilterState>(
    filter?.relates ? cloneObject(filter?.relates) : DEFAULT_RELATES_FILTER
  );

  useEffect(() => {
    // when filters are reset from above in useFilterSortState, we would like to reset the state in here as well.
    // As Select component is not able to react to changes in initial state, we temporarily unmount and then
    // immediately remount component again. We set the initial state to the default row.
    if (!initialized) {
      setRelatesFilter(DEFAULT_RELATES_FILTER);
    }
  }, [initialized]);

  const onUpdate = (newRelatesState: RelatesFilterState) => {
    setRelatesFilter(newRelatesState);
    applyFilters(newRelatesState);
  };
  const applyFilters = (newRow: RelatesFilterState) => {
    onFilterChange({
      key: fieldName,
      op_id: FilterOperator.RELATES,
      values: [encodeRelatesFilter(newRow)],
      extra: undefined,
      relates: cloneObject(newRow),
    });
  };

  if (!initialized) return <FilterSkeleton />;

  return (
    <>
      <RelatedFilterRow
        relatesState={relatesFilter}
        onUpdate={onUpdate}
        baseEntityText={baseEntityText}
        disableSelectingWindowDirection={disableSelectingWindowDirection}
      />
      <div className="flex items-center justify-end mt-3">
        <ResetToDefaultButton
          filters={filters}
          defaultFilters={defaultFilters}
          fieldName={fieldName}
          testId="relates-reset-to-default"
          onResetToDefault={onFiltersReset}
        />
      </div>
    </>
  );
};

export default RelatesFilter;
