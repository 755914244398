import { getRespondToUserTool } from "duck/agents/ClaimAnalytics/utils/tools/getTools";
import { GraphStateType } from "duck/agents/common/utils/state";
import { ToolNode } from "@langchain/langgraph/prebuilt";

/**
 * @summary Node responsible for running the final response tool
 * @returns Final response node
 */
const getRespondToUserNode = (): ToolNode<GraphStateType> =>
  new ToolNode<GraphStateType>([getRespondToUserTool()]);

export default getRespondToUserNode;
