import FormSection from "pages/SignalEventStudio/Common/FormSection";

import VINSearch from "features/layout/SidebarNav/VINSearch";
import Button from "features/ui/Button";

interface Props {
  selectedVIN?: string;
  setSelectedVIN: (vin: string) => void;
  setEditMode: (editModel: boolean) => void;
  editMode: boolean;
}

const VINSelectSection = ({
  selectedVIN,
  setSelectedVIN,
  setEditMode,
  editMode,
}: Props) => (
  <FormSection
    title="Test VIN Events"
    text="See how the custom event definition performs on any VIN"
  >
    <div className="flex items-center space-x-3 my-4">
      <span>VIN</span>
      <span className="w-64">
        <VINSearch
          label="Enter VIN to see events"
          onChangeHandler={setSelectedVIN}
          value={selectedVIN}
        />
      </span>
      {selectedVIN && (
        <Button
          data-testid="btn-show-events"
          color="primary"
          variant="outlined"
          onClick={() => setEditMode(!editMode)}
        >
          {editMode ? "Show Events" : "Hide Events (Allow definition editing)"}
        </Button>
      )}
    </div>
  </FormSection>
);

export default VINSelectSection;
