import { useEffect, useRef } from "react";

import DuckLogo from "./duck-logo.svg";

const DuckLoadingAnimation = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  /**
   * The animation moves a percentage of the container div.
   * The animation does not normally have access to the width of the container,
   * so we have to jump through these hoops to provide it to the animation
   * via a CSS property. The value stays accurate as the container resizes.
   */
  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        containerRef.current.style.setProperty(
          "--container-width",
          `${width}px`
        );
      }
    };

    updateContainerWidth();

    const resizeObserver = new ResizeObserver(updateContainerWidth);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className="w-full h-[22px] relative px-[20%]">
      <div ref={containerRef} className="w-full h-full relative">
        <img
          className="h-[22px] w-[22px] absolute left-0 animate-duck-loading"
          src={DuckLogo}
          alt="Duck Logo"
        />
      </div>
    </div>
  );
};

export default DuckLoadingAnimation;
