import { ChatPromptTemplate } from "@langchain/core/prompts";
import { StructuredTool } from "@langchain/core/tools";
import { ChatOpenAI, ChatOpenAICallOptions } from "@langchain/openai";

import { createAgentNode, NodeType } from "./utils";

const endToolDef = {
  type: "function",
  function: {
    name: "END",
    description: "End the conversation",
    parameters: {
      type: "object",
      properties: {},
      additionalProperties: false,
    },
  },
} as const;

/**
 * @summary Create and return the agent node with primary responsibility for processing the user's utterance
 * by delegating to an LLM and other nodes. This Agent is a strict agent that requires a tool to be chosen.
 * @param llmAgent The LLM agent that processes the utterance
 * @param tools The tools available to the LLM
 * @param claimAnalyticsPrompt The prompt to send to the LLM
 * @returns The node responsible for running the rejecting or clarifying the user's utterance.
 */
const getAgentNode = (
  llmAgent: ChatOpenAI<ChatOpenAICallOptions>,
  tools: StructuredTool[],
  claimAnalyticsPrompt: ChatPromptTemplate
): NodeType => {
  return createAgentNode(
    llmAgent,
    [...tools, endToolDef] as StructuredTool[],
    claimAnalyticsPrompt,
    {
      strict: true,
      tool_choice: "required",
    }
  );
};

export default getAgentNode;
