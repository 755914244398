import { APIErrorResponse } from "shared/api/api";
import { CustomSignalEvent } from "shared/api/customSignalEvents/api";

import FormSection from "pages/SignalEventStudio/Common/FormSection";
import { CustomAttribute } from "pages/SignalEventStudio/Definition/CustomAttributes/CustomAttributeSelect";
import { combineCustomEventsTableSchema } from "pages/SignalEventStudio/utils";

import APIError from "features/ui/APIError";
import Table, { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";

interface Props {
  customAttributes: CustomAttribute[];
  baseEventTypeSchema: SchemaEntry[] | undefined;
  data?: CustomSignalEvent[];
  dataIsLoading: boolean;
  dataError?: APIErrorResponse;
  countIsLoading: boolean;
  countError?: APIErrorResponse;
  count?: number;
  className?: string;
}
// We skip those because we only show events for 1 vin, and we replace timestamp with top-level date column.
const SKIPPED_ACCESSORS = ["VIN", "recordedAt", "readAt", "date"];

const CustomEventsTableSection = ({
  customAttributes = [],
  baseEventTypeSchema,
  data,
  dataIsLoading,
  dataError,
  count,
  countIsLoading,
  countError,
  className,
}: Props) => {
  const customAttributesSchema: SchemaEntry[] = customAttributes.map(
    (attribute) => ({
      label: attribute.label,
      accessor: `customAttributes.${attribute.id}`,
      dataType: "number" as DataType,
    })
  );

  const combinedSchema = combineCustomEventsTableSchema(
    baseEventTypeSchema,
    customAttributesSchema,
    SKIPPED_ACCESSORS
  );

  return (
    <FormSection title="Events for Selected Vehicle" className={className}>
      {!dataError && (
        <Table
          testId={"custom-signal-events-table"}
          data={data}
          schema={combinedSchema}
          isLoading={dataIsLoading || countIsLoading}
          loadingRows={40}
          filtersInitialized={true}
          stickyFirstColumn={true}
          scrollHeight={500}
          dense
        />
      )}
      {dataError && <APIError error={dataError} />}
      <div className="flex items-end mt-3">
        <TableCount
          extraClasses="ml-auto self-end"
          count={data?.length}
          prefix="Showing lastest "
          suffix={` out of ${count}`}
          entityName="custom signal event"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
    </FormSection>
  );
};

export default CustomEventsTableSection;
