import classNames from "classnames";

import {
  AGGREGATION_OPTIONS,
  DIRECTION_OPTIONS,
  UNIT_OPTIONS,
} from "pages/SignalEventStudio/constants";
import { CustomAttribute } from "pages/SignalEventStudio/Definition/CustomAttributes/CustomAttributeSelect";
import { FunctionInputConfigType } from "pages/SignalEventStudio/types";

import DropdownSelect from "features/ui/DropdownSelect/DropdownSelect";
import Input from "features/ui/Input";
import { SchemaEntry } from "features/ui/Table";

import EventFilters from "./EventFilters";
import EventTypeSelect from "./EventTypeSelect";
import FieldSelect from "./FieldSelect";

export interface FunctionInputProps {
  config: FunctionInputConfig;
  attribute: CustomAttribute;
  onChange: (attribute: CustomAttribute) => void;
  fieldSchema?: SchemaEntry[];
  baseEventType: string;
  combineFieldSchemas?: boolean;
  useSelectedEvent?: boolean;
  disabled?: boolean;
}

const FunctionInput = ({
  config,
  attribute,
  onChange,
  baseEventType,
  fieldSchema,
  disabled,
}: FunctionInputProps) => (
  <div className="flex space-x-2">
    <div
      className={classNames("w-20 text-right shrink-0 leading-9", {
        "pt-3": config.type === "event_filter",
      })}
    >
      {config.labelBefore}
    </div>
    {getInputByType(
      config,
      attribute,
      onChange,
      baseEventType,
      fieldSchema,
      disabled
    )}
    {config.labelAfter && <div className="leading-9">{config.labelAfter}</div>}
  </div>
);

export interface FunctionInputConfig {
  type: FunctionInputConfigType;
  parameter: keyof CustomAttribute;
  labelBefore: string;
  labelAfter?: string;
  fieldSchema?: SchemaEntry[];
  dataTypes?: string[];
  useSelectedEvent?: boolean;
}

const getInputByType = (
  config: FunctionInputConfig,
  attribute: CustomAttribute,
  onChange: (attribute: CustomAttribute) => void,
  baseEventType: string,
  fieldSchema?: SchemaEntry[],
  disabled?: boolean
): JSX.Element | null => {
  switch (config.type) {
    case "event_type":
      return (
        <EventTypeSelect
          attribute={attribute}
          onChange={onChange}
          baseEventType={baseEventType}
          config={config}
          disabled={disabled}
        />
      );
    case "event_filter":
      return (
        <EventFilters
          attribute={attribute}
          onChange={onChange}
          baseEventType={baseEventType}
          config={config}
          disabled={disabled}
        />
      );
    case "field":
      return (
        <FieldSelect
          attribute={attribute}
          onChange={onChange}
          config={config}
          baseEventType={baseEventType}
          fieldSchema={fieldSchema}
          useSelectedEvent={config.useSelectedEvent}
          disabled={disabled}
        />
      );
    case "combined_field":
      return (
        <FieldSelect
          attribute={attribute}
          onChange={onChange}
          config={config}
          baseEventType={baseEventType}
          fieldSchema={fieldSchema}
          combineFieldSchemas={true}
          disabled={disabled}
        />
      );
    case "aggregation_function":
      return (
        <DropdownSelect
          label={(attribute[config.parameter] as string) || "Select function"}
          options={AGGREGATION_OPTIONS}
          onSelect={(selectedOption) =>
            onChange({
              ...attribute,
              [config.parameter]: selectedOption.id as string,
            })
          }
          disabled={disabled}
        />
      );
    case "direction":
      return (
        <DropdownSelect
          label={(attribute[config.parameter] as string) || "Select direction"}
          options={DIRECTION_OPTIONS}
          onSelect={(selectedOption) =>
            onChange({
              ...attribute,
              [config.parameter]: selectedOption.id as string,
            })
          }
          disabled={disabled}
        />
      );
    case "unit":
      return (
        <DropdownSelect
          label={
            (UNIT_OPTIONS.find(
              ({ id }) => id === (attribute[config.parameter] as string)
            )?.value as string) || "Select unit"
          }
          options={UNIT_OPTIONS}
          onSelect={(selectedOption) =>
            onChange({
              ...attribute,
              [config.parameter]: selectedOption.id as string,
            })
          }
          disabled={disabled}
        />
      );
    case "text":
      return (
        <Input
          label="Before"
          value={attribute[config.parameter] as string}
          onChange={({ target: { value } }) =>
            onChange({ ...attribute, [config.parameter]: Number(value) })
          }
          fullWidth={false}
          className="w-32"
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};

export default FunctionInput;
