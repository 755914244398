import { Dispatch, SetStateAction } from "react";

import {
  ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_KEY,
  BY_VEHICLES_AGE_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "pages/constants";
import { ByVehicleAgeData } from "pages/types";

import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { UseFilterSortState } from "features/ui/Filters/types";
import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import { REPAIRS_ANALYTICS_TABS, REPAIRS_TAB_KEY } from "./constants";
import AssociatedSignalEvents from "./tabPages/AssociatedSignalEvents";
import AssociatedVehicles from "./tabPages/AssociatedVehicles";
import ByVehicleAge from "./tabPages/ByVehicleAge";
import Repairs from "./tabPages/Repairs";
import TopContributors from "./tabPages/TopContributors";

export interface RepairAnalyticsProps {
  repairsFiltersFilterSortState?: UseFilterSortState;
  vehiclesFiltersFilterSortState?: UseFilterSortState;
}

export interface RepairsFiltersProps {
  repairsFilters?: FilterGroupState;
  vehiclesFilters?: FilterGroupState;
  onBadRequest: () => void;
}

export interface RepairAnalyticsTabsProps
  extends RepairsFiltersProps,
    RepairAnalyticsProps {
  queryParamsToPersist?: string[];
}

export interface RepairsByVehicleAgeChartProps extends RepairsFiltersProps {
  byVehicleAgeData: ByVehicleAgeData;
  actions: ChartAction[];
  selectedOptions: SelectedChartOptions[];
  setSelectedOptions: Dispatch<SetStateAction<SelectedChartOptions[]>>;
}

export interface RepairsByVehicleAgeTableProps extends RepairsFiltersProps {
  byVehicleAgeData: ByVehicleAgeData;
}

const RepairAnalyticsTabs = ({
  repairsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
  queryParamsToPersist,
  onBadRequest,
}: RepairAnalyticsTabsProps) => {
  const repairsFilters = repairsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  const tabsItems: (Tab | boolean | undefined)[] = [
    {
      key: REPAIRS_TAB_KEY,
      title: REPAIRS_ANALYTICS_TABS[REPAIRS_TAB_KEY],
      content: (
        <Repairs
          repairsFilters={repairsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: BY_VEHICLES_AGE_TAB_KEY,
      title: REPAIRS_ANALYTICS_TABS[BY_VEHICLES_AGE_TAB_KEY],
      content: (
        <ByVehicleAge
          repairsFilters={repairsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: TOP_CONTRIBUTORS_TAB_KEY,
      title: REPAIRS_ANALYTICS_TABS[TOP_CONTRIBUTORS_TAB_KEY],
      content: (
        <TopContributors
          repairsFiltersFilterSortState={repairsFiltersFilterSortState}
          vehiclesFiltersFilterSortState={vehiclesFiltersFilterSortState}
        />
      ),
    },
    {
      key: ASSOCIATED_VEHICLES_TAB_KEY,
      title: REPAIRS_ANALYTICS_TABS[ASSOCIATED_VEHICLES_TAB_KEY],
      content: (
        <AssociatedVehicles
          repairsFilters={repairsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
      title: REPAIRS_ANALYTICS_TABS[ASSOCIATED_SIGNAL_EVENTS_TAB_KEY],
      content: (
        <AssociatedSignalEvents
          repairsFiltersFilterSortState={repairsFiltersFilterSortState}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return (
    <StatefulTabs tabs={tabs} queryParamsToPersist={queryParamsToPersist} />
  );
};

export default RepairAnalyticsTabs;
