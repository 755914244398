import { Handlers } from "duck/agents/ClaimAnalytics/types";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { TOP_CONTRIBUTORS_TAB_KEY } from "pages/constants";

const getGoToTopContributorsTabTool = (
  changeTabHandler: Handlers["changeTab"],
  setSelectedGroupByAttributeHandler: Handlers["setSelectedGroupByAttribute"]
): DynamicStructuredTool => {
  const goToTopContributorsTabSchema = z.object({
    groupByAttribute: z
      .string()
      .optional()
      .describe("Group by attribute. Default value: 'laborCode'."),
  });

  type GoToTopContributorsTab = z.infer<typeof goToTopContributorsTabSchema>;

  const goToTopContributorsTab = ({
    groupByAttribute,
  }: GoToTopContributorsTab) => {
    console.debug(
      "Navigating to Top Contributors tab with group by attribute:",
      groupByAttribute
    );

    changeTabHandler(TOP_CONTRIBUTORS_TAB_KEY);

    if (groupByAttribute) {
      console.debug("Updating group by attribute");
      setSelectedGroupByAttributeHandler(groupByAttribute);
    }

    return "success";
  };

  return tool(goToTopContributorsTab, {
    name: "goToTopContributorsTab",
    description: `Call this tool to queue a navigation to the Top Contributors tab action.`,
    schema: goToTopContributorsTabSchema,
  });
};

export default getGoToTopContributorsTabTool;
