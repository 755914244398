import { useState } from "react";
import {
  ByVehicleAgeChartOptionStrings,
  ClaimsChartOptionStrings,
  PageState,
} from "duck/agents/ClaimAnalytics/types";
import {
  deliverLocationInfo,
  Reload,
} from "duck/agents/common/utils/handlers/queryStringNavigation";
import { useNavigate } from "react-router";

import { useGroupBySelectOptions } from "shared/schemas/hooks";
import { randomID } from "shared/utils";

import { useByVehicleAgeChartActions } from "pages/ClaimAnalytics/tabPages/ByVehicleAge/hooks";
import { useClaimsChartActions } from "pages/ClaimAnalytics/tabPages/Claims/hooks";

import { LANGCHAIN_THREAD_ID_KEY } from "./constants";
import {
  getByVehicleAgeChartOptionStrings,
  getClaimsChartOptionStrings,
  getInitialThreadId,
  getInitialVisibility,
  getPageState,
  persistVisibility,
  toNonEmptyStringArray,
} from "./utils";

type AgentData = {
  claimsChartOptions: ClaimsChartOptionStrings;
  byVehicleAgeChartOptions: ByVehicleAgeChartOptionStrings;
  groupBySelectOptions: string[];
  getPageState: () => PageState;
};

/**
 * This hook assembles the data that the Duck agent needs in order to do its work.
 * For now, all of this data is related to the claim analytics page.
 *
 * Most of the data is relatively static, and is provided at the time the hook runs.
 * The hook also provides a getPageState function that can be called at the time
 * the agent is called in order to get the more dynamic data that is needed.
 */
export const useAgentData = (): AgentData => {
  const chartClaimsChartOptions = useClaimsChartActions();
  const byVehicleAgeChartActions = useByVehicleAgeChartActions();
  // This next block is a quick short term fix for DUCK-149.
  // The hook temporarily returns an empty array while the data loads.
  let rawGroupBySelectOptions = useGroupBySelectOptions("claim");
  if (!rawGroupBySelectOptions || rawGroupBySelectOptions.length === 0) {
    rawGroupBySelectOptions = [
      {
        id: "placeholder",
        value: "Placeholder While Data Loads",
      },
    ];
  }

  return {
    claimsChartOptions: getClaimsChartOptionStrings(chartClaimsChartOptions),
    byVehicleAgeChartOptions: getByVehicleAgeChartOptionStrings(
      byVehicleAgeChartActions
    ),
    groupBySelectOptions: toNonEmptyStringArray(rawGroupBySelectOptions),
    getPageState,
  };
};

export const useDuckVisibility = (forceOpen?: boolean) => {
  const [open, setOpen] = useState(forceOpen || getInitialVisibility());

  if (forceOpen) {
    persistVisibility(true);
  }

  const setIsDuckVisible = (visible: boolean) => {
    setOpen(visible);
    persistVisibility(visible);
  };

  return { isDuckVisible: open, setIsDuckVisible };
};

/**
 * @summary This hook provides a thread id for the Duck agent, and also provides
 * a mechanism to reset it. Resetting the memory of the Duck session is accomplished
 * by resetting the thread id.
 * @returns The current thread id and a function to reset it.
 */
export const useThreadId = () => {
  const [threadId, setThreadId] = useState(getInitialThreadId());

  const resetThreadId = () => {
    const updatedThreadId = randomID();
    if (sessionStorage) {
      sessionStorage.setItem(LANGCHAIN_THREAD_ID_KEY, updatedThreadId);
    }
    setThreadId(updatedThreadId);
  };

  return { threadId, resetThreadId };
};

/**
 * This hook works in conjunction with the queryStringNavitation utility.
 * It takes the user to the location specified by it, and does a soft or hard reload
 * based on it.
 */
export const useUpdateLocation = (): (() => void) => {
  const navigate = useNavigate();

  return () => {
    const { path, url, reloadRequired } = deliverLocationInfo();
    if (reloadRequired === Reload.HARD) {
      window.location.assign(url);
    } else if (reloadRequired === Reload.SOFT) {
      navigate(path);
    }
  };
};
