import { useMemo } from "react";
import { generatePath, Link } from "react-router-dom";

import { getSortFilter } from "shared/api/utils";
import {
  FailureModePrediction,
  getFailureModePredictionsExport,
} from "shared/api/v0_failureModes/api";
import {
  useFailureMode,
  useFailureModePredictions,
  useFailureModePredictionsCount,
} from "shared/api/v0_failureModes/hooks";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import {
  FAILURE_MODE_FILTER,
  FAILURE_MODE_PREDICTIONS_VIN_FILTER,
  RISK_FILTER,
  RISK_STATUS_FILTER,
} from "shared/filterDefinitions";
import { SortBy } from "shared/types";

import {
  repairStatusDescription,
  riskGroupDescription,
  riskMultipleDescription,
  riskStatusDescription,
} from "pages/Vehicles/content";
import RiskBadge from "pages/Vehicles/RiskBadge";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import TableAsyncCellValue from "features/ui/Table/TableAsyncCellValue";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";
import Title from "features/ui/Title";

import { routes } from "services/routes";

const ROWS_PER_PAGE = 10;

const formatRow = ({
  VIN,
  failureModeID,
  riskGroup,
  riskMultiple,
  ...otherProps
}: FailureModePrediction) => {
  const pathname = generatePath(routes.vinView, {
    vin: encodeURIComponent(VIN),
  });

  return {
    riskGroup: <RiskBadge risk={riskGroup} />,
    target: <TableAsyncCellValue id={failureModeID} hook={useFailureMode} />,
    VIN: (
      <Link to={pathname} className="text-metabase-blue hover:underline">
        {VIN}
      </Link>
    ),
    riskMultiple,
    ...otherProps,
  };
};

const SCHEMA: SchemaEntry[] = [
  {
    label: "VIN",
    accessor: "VIN",
    dataType: DataType.JSX,
    filter: FAILURE_MODE_PREDICTIONS_VIN_FILTER,
  },
  {
    label: "Failure Mode",
    accessor: "target",
    dataType: DataType.JSX,
    sortable: false,
    filter: FAILURE_MODE_FILTER,
  },
  {
    label: "Risk Group",
    accessor: "riskGroup",
    dataType: DataType.JSX,
    sortable: true,
    description: riskGroupDescription,
    filter: RISK_FILTER,
  },
  {
    label: "Risk Status",
    accessor: "riskStatus",
    dataType: DataType.STRING,
    filter: RISK_STATUS_FILTER,
    description: riskStatusDescription,
  },
  {
    label: "Risk Multiple",
    accessor: "riskMultiple",
    dataType: DataType.NUMBER,
    description: riskMultipleDescription,
    sortable: true,
  },
  {
    label: "Repair Status",
    accessor: "repairStatus",
    dataType: DataType.STRING,
    description: repairStatusDescription,
  },
  {
    label: "Repair Details",
    accessor: "repairDetails",
    dataType: DataType.STRING,
  },
];

interface PredictionsProps {
  title?: string;
}

const PAGE_KEY = "predictions";
const DEFAULT_SORT: SortBy = { riskGroup: "desc" };

const Predictions = ({ title }: PredictionsProps) => {
  const {
    manageOnFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const requestParams = {
    filter: getFiltersQuery(filters),
    sort: getSortFilter(sort),
    limit: ROWS_PER_PAGE,
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useFailureModePredictions(requestParams);

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useFailureModePredictionsCount({ filter: requestParams.filter });

  const formattedData = useMemo(() => data?.map(formatRow), [data]);

  const downloadDisabled = !formattedData || formattedData.length === 0;

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  return (
    <>
      <PageHeaderWrapper>
        {title && <Title text={title} docsSectionId="predictions" />}
        <PageHeaderActionsWrapper>
          <DownloadAction
            disabled={downloadDisabled}
            downloadFunc={getFailureModePredictionsExport}
            fileName="Predictions"
            entityName="prediction"
            count={countData?.count}
            requestParams={{
              ...requestParams,
              limit: MAX_ROWS_DOWNLOAD_LIMIT,
            }}
            filters={filters}
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <h5 className="text-gray-500 text-sm max-w-xl">
        See and download predictions for active, onboarded failure modes.
      </h5>
      <div className="flex items-center my-3">
        <FiltersOverview
          filters={filters}
          tableSchema={SCHEMA}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto self-end"
          count={countData?.count as number}
          entityName="prediction"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={SCHEMA}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageOnFilterChange}
          filters={filters}
          pageKey={PAGE_KEY}
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
    </>
  );
};

export default Predictions;
