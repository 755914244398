import { useFlags } from "launchdarkly-react-client-sdk";
import { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import { useFailureMode as useFailureModeV0 } from "shared/api/v0_failureModes/hooks";

// TODO: replace this with actual hook
const useFailureMode = () => ({
  isLoading: false,
  error: false,
  data: {
    name: "Failure Modes V1 Breadcrumb is not yet available",
  },
});

interface Props {
  match: BreadcrumbMatch;
}

const FailureModeBreadcrumb = ({ match }: Props) => {
  const { failureModesV1 } = useFlags();

  const dataFetchHook = failureModesV1 ? useFailureMode : useFailureModeV0;

  const {
    data: failureMode,
    isLoading,
    error,
  } = dataFetchHook({
    id: match.params.id as string,
  });

  if (isLoading) {
    return <></>;
  }

  if (error && match.params.id) {
    return <>{match.params.id}</>;
  }

  return <>{failureMode?.name}</>;
};

export default FailureModeBreadcrumb;
