import { useState } from "react";

import { useCustomLocalStorageState } from "shared/hooks";

import { SignalEventsAnalyticsTabsProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import {
  DEFAULT_RELATES_FILTER,
  DEFAULT_RELATES_FILTER_FOR_OPERATOR,
} from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import RelatedFilterRow from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterRow";
import { FilterOperator, RelatesFilterState } from "features/ui/Filters/types";

import AssociatedSignalEventsTable from "./AssociatedSignalEventsTable";

const RELATES_FILTER_KEY = "se-associated-se-relates-filter-v2";

const IS_NOT_FILTERED_FILTER_DISABLED = true;

const DEFAULT_FILTER_FOR_RELATES_FILTER = IS_NOT_FILTERED_FILTER_DISABLED
  ? DEFAULT_RELATES_FILTER_FOR_OPERATOR(FilterOperator.IN)
  : DEFAULT_RELATES_FILTER;

const AssociatedSignalEvents = ({
  signalEventsFiltersFilterSortState,
  vehiclesFilters,
  onBadRequest,
}: SignalEventsAnalyticsTabsProps) => {
  const [appliedRelatesFilter, setAppliedRelatesFilter] =
    useCustomLocalStorageState<RelatesFilterState>(RELATES_FILTER_KEY, {
      defaultValue: DEFAULT_FILTER_FOR_RELATES_FILTER,
    });
  const [relatesFilter, setRelatesFilter] =
    useState<RelatesFilterState>(appliedRelatesFilter);

  const onUpdateRelatesFilter = (row: RelatesFilterState) => {
    setRelatesFilter(row);
  };

  const onCancel = () => {
    setRelatesFilter(DEFAULT_FILTER_FOR_RELATES_FILTER);
    setAppliedRelatesFilter(DEFAULT_FILTER_FOR_RELATES_FILTER);
  };

  const onApply = () => {
    setAppliedRelatesFilter(relatesFilter);
  };

  return (
    <>
      <RelatedFilterRow
        relatesState={relatesFilter}
        appliedRelatesFilter={appliedRelatesFilter}
        inFilterSelector={false}
        baseEntityText="Base Signal Event"
        onUpdate={onUpdateRelatesFilter}
        onApply={onApply}
        onCancel={onCancel}
        disableIsNotFilteredFilters={IS_NOT_FILTERED_FILTER_DISABLED}
      />
      <AssociatedSignalEventsTable
        onBadRequest={onBadRequest}
        signalEventsFiltersFilterSortState={signalEventsFiltersFilterSortState}
        vehiclesFilters={vehiclesFilters}
        relatesFilter={appliedRelatesFilter}
      />
    </>
  );
};

export default AssociatedSignalEvents;
