import { RefObject, useState } from "react";
import { createPortal } from "react-dom";

import { DUCK_POPPED_INITIAL_HEIGHT, DUCK_POPPED_INITIAL_X } from "./constants";
import DraggableDuck from "./DraggableDuck";
import Duck from "./Duck";

interface DuckNestProps {
  draggableDuckRef: RefObject<HTMLDivElement | null>;
}

const DuckNest = ({ draggableDuckRef }: DuckNestProps) => {
  const [isDuckDraggable, setIsDuckDraggable] = useState(false);

  const setDuckIsDraggable = () => {
    setIsDuckDraggable(true);
  };

  const setDuckIsNotDraggable = () => {
    setIsDuckDraggable(false);
  };

  const getDuckInitialYPosition = () => {
    const bottomMargin = 20;
    return window.innerHeight - DUCK_POPPED_INITIAL_HEIGHT - bottomMargin;
  };

  return (
    <>
      {isDuckDraggable &&
        draggableDuckRef.current &&
        createPortal(
          <DraggableDuck
            initialPosition={{
              x: DUCK_POPPED_INITIAL_X,
              y: getDuckInitialYPosition(),
            }}
            setDuckIsNotDraggable={setDuckIsNotDraggable}
          />,
          draggableDuckRef.current
        )}

      {!isDuckDraggable && (
        // This empty div is necessary for Duck to correctly set its height.
        <div>
          <Duck setDuckIsDraggable={setDuckIsDraggable} />
        </div>
      )}
    </>
  );
};

export default DuckNest;
