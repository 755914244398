import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { jwtDecode } from "jwt-decode";
import useLocalStorageState, {
  LocalStorageOptions,
} from "use-local-storage-state";
import { useOktaAuth } from "@okta/okta-react";

import { getDefaultRelatedSignalEventsTimePeriod } from "pages/Issues/utils";
import { useVehiclesSchemaWithFailureModes } from "pages/Vehicles/hooks";

import { FilterSchemaItem } from "features/ui/Filters/types";
import { getPageKeyWithVersion } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";

import { useClaimsSchema } from "./schemas/claimsSchema";
import { useInspectionsSchema } from "./schemas/inspectionsSchema";
import useIssuesSchema from "./schemas/issuesSchema";
import { useRepairsSchema } from "./schemas/repairsSchema";
import useSensorReadingsSchema from "./schemas/sensorReadingsSchema";
import useServiceRecommendationsSchema from "./schemas/serviceRecommendationsSchema";
import useSignalEventOccurrencesSchema from "./schemas/signalEventOccurrencesSchema";
import useVehicleECUsCombinedSchema from "./schemas/vehicleECUsCombinedSchema";
import { EventTypeEnum, JWT } from "./types";

export const useDebounce = <T>(value: T, delay: number): T => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

/**
 * From https://gist.github.com/Danziger/336e75b6675223ad805a88c2dfdcfd4a
 * and modified to use dependencies also.
 * Example use:
 * useTimeout(() => setShowTooltip(false), HIDE_TOOLTIP_AFTER_MS, [showTooltip]);
 */
export const useTimeout = (
  callback: React.EffectCallback,
  delay: number | null,
  dependencies: any[] = []
): React.MutableRefObject<number | null> => {
  const timeoutRef = useRef<number | null>(null);
  const callbackRef = useRef(callback);
  const dependenciesStr = JSON.stringify(dependencies);

  // Remember the latest callback:
  //
  // Without this, if you change the callback, when setTimeout kicks in, it
  // will still call your old callback.
  //
  // If you add `callback` to useEffect's deps, it will work fine but the
  // timeout will be reset.

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the timeout:
  useEffect(() => {
    if (typeof delay === "number") {
      timeoutRef.current = window.setTimeout(
        () => callbackRef.current(),
        delay
      );

      // Clear timeout if the components is unmounted or the delay or dependencies change:
      return () => window.clearTimeout(timeoutRef.current || 0);
    }
  }, [delay, dependenciesStr]);

  // In case you want to manually clear the timeout from the consuming component...:
  return timeoutRef;
};

// From https://usehooks.com/useWindowSize/, adjusted
export const useWindowSize = (debounce: number = 500) => {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  const debouncedWindowSize = useDebounce(windowSize, debounce);

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return debouncedWindowSize;
};

export const useEmailFromJWT = (): string => {
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken() || "";

  if (!accessToken) return "";

  const { sub: email }: JWT = jwtDecode(accessToken);

  return (email as string).toLowerCase() || "";
};

export const useClaimsFiltersSchema = (): SchemaEntry[] => {
  // we currently take all cols from Claims table and filter out the ones that don't have a filter
  return useClaimsSchema().schema.filter(
    ({ filter, hideFilter }) => filter && !hideFilter
  );
};

export const useInspectionsFiltersSchema = (): SchemaEntry[] => {
  return useInspectionsSchema().schema.filter(
    ({ filter, hideFilter }) => filter && !hideFilter
  );
};

export const useRepairsFiltersSchema = (): SchemaEntry[] => {
  return useRepairsSchema().schema.filter(
    ({ filter, hideFilter }) => filter && !hideFilter
  );
};

export const useIssuesFiltersSchema = (): SchemaEntry[] => {
  return useIssuesSchema().schema.filter(
    ({ filter, hideFilter }) => filter && !hideFilter
  );
};

interface UseFiltersSchemaProps {
  disableIsNotFilteredFilters?: boolean;
}

export const useVehiclesFiltersSchema = (
  options?: UseFiltersSchemaProps
): SchemaEntry[] => {
  const { disableIsNotFilteredFilters } = options || {};

  const { schema: vehicleAttributesSchema } =
    useVehiclesSchemaWithFailureModes();

  const { schema: ECUSchema } = useVehicleECUsCombinedSchema();

  const allVehicleAttributes: SchemaEntry[] = [
    ...vehicleAttributesSchema,
    ...ECUSchema,
  ].map((schema) => ({
    ...schema,
    filter: {
      ...schema.filter,
      disableIsNotFilteredFilters,
    } as FilterSchemaItem,
  }));

  return allVehicleAttributes.filter(
    ({ filter, hideFilter }: SchemaEntry) => Boolean(filter) && !hideFilter
  );
};

export const useServiceRecommendationsFiltersSchema = (
  options?: UseFiltersSchemaProps
): SchemaEntry[] => {
  const { disableIsNotFilteredFilters } = options || {};

  const { schema } = useServiceRecommendationsSchema();

  return schema
    .filter(({ filter, hideFilter }: SchemaEntry) => filter && !hideFilter)
    .map((schema) => ({
      ...schema,
      filter: {
        ...schema.filter,
        disableIsNotFilteredFilters,
      } as FilterSchemaItem,
    }));
};

export const useSignalEventOccurrencesFiltersSchema = (
  accessorsToHide: string[] = []
): SchemaEntry[] => {
  const { schema } = useSignalEventOccurrencesSchema(accessorsToHide);

  return schema.filter(
    ({ filter, hideFilter }: SchemaEntry) => filter && !hideFilter
  );
};

export const useSensorReadingsFiltersSchema = (): SchemaEntry[] => {
  return useSensorReadingsSchema().schema.filter(
    ({ filter, hideFilter }) => filter && !hideFilter
  );
};

export const useFlexibleTimePeriod = (key: string) =>
  useCustomLocalStorageState(key, {
    defaultValue: getDefaultRelatedSignalEventsTimePeriod().id,
  });

export const useFilterSchemaForType = (
  type?: EventTypeEnum
): SchemaEntry[] | undefined => {
  const signalEventOccurrencesSchema = useSignalEventOccurrencesFiltersSchema();
  const claimsSchema = useClaimsFiltersSchema();
  const sensorReadingsSchema = useSensorReadingsFiltersSchema();
  const repairsSchema = useRepairsFiltersSchema();
  const inspectionsSchema = useInspectionsFiltersSchema();

  switch (type) {
    case EventTypeEnum.CLAIM:
      return claimsSchema;
    case EventTypeEnum.SIGNAL_EVENT:
      return signalEventOccurrencesSchema;
    case EventTypeEnum.SENSOR_READING:
      return sensorReadingsSchema;
    case EventTypeEnum.REPAIR:
      return repairsSchema;
    case EventTypeEnum.INSPECTION:
      return inspectionsSchema;
  }
};

/**
 * Just a custom wrapper around useLocalStorageState that adds a version suffix to the key
 * so we can bump all keys in our app at once.
 * Always use this instead of useLocalStorageState.
 */
export const useCustomLocalStorageState = <T>(
  key: string,
  options: LocalStorageOptions<T>
) =>
  useLocalStorageState<T>(getPageKeyWithVersion(key), {
    ...options,
    defaultValue: options.defaultValue ?? undefined,
  });
