import { add, format } from "date-fns";
import { toDate } from "date-fns-tz";
import { XAxisProps } from "recharts";

import {
  IssueRepairEfficacyVINTimeline,
  IssuesOverview,
  SuggestedIssuesOverview,
} from "shared/api/issues/api";
import { SuggestedIssue } from "shared/api/suggestedIssues/api";
import { ISSUES_ROUTE, SUGGESTED_ISSUE_RUNS_ROUTE } from "shared/constants";
import {
  BucketByIssuesEnum,
  BucketBySuggestedIssuesEnum,
  ChartActionID,
  GroupByIssuesEnum,
  GroupBySuggestedIssuesEnum,
  IssueChart,
  IssueChartType,
  IssueTypes,
  IssueVehiclePopulation,
  ValueType,
} from "shared/types";
import {
  formatNumber,
  formatPercentValue,
  isNumeric,
  randomID,
} from "shared/utils";

import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import {
  DataElement,
  LegendConfigLabel,
  YAxisLine,
} from "features/ui/charts/types";
import {
  generateXAxisTickProps,
  getAxisValue,
  getColor,
  getMISTicksFromDIS,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { FilterOperator } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";

import * as config from "config/config";

import {
  DASHBOARD_GROUP_BY_KEY,
  DEFAULT_ISSUES_FILTER,
  EMPTY_CHART_LABEL,
  EMPTY_GROUP_CHART_LABEL,
  ISSUES_CHART_ACTIONS,
  MEASURE_PERCENTAGE_LABELS,
} from "./constants";
import { ageOptions } from "./types";

const CHART_OPTIONS_KEY_PREFIX = "chartOptions";
const CHART_OPTIONS_KEY_SEPARATOR = ".";

export const getPopulationKeyValuePairs = () => [
  { id: "At-Risk", value: "At-Risk" },
  { id: "Comparison", value: "Comparison" },
];

export const getChartOptionsKey = (
  name: string,
  ID: string,
  versionSuffix?: string
) =>
  [CHART_OPTIONS_KEY_PREFIX, name, ID, versionSuffix]
    .filter(Boolean)
    .join(CHART_OPTIONS_KEY_SEPARATOR);

export const getRelatedSignalEventsTimePeriods = (): SelectOption<number>[] => [
  { id: 1, value: 1, testId: "period-1" },
  { id: 3, value: 3, testId: "period-3" },
  { id: 7, value: 7, testId: "period-7" },
  { id: 14, value: 14, testId: "period-14" },
  { id: 30, value: 30, testId: "period-30" },
];

export const getSignalEventReoccurrencesTimePeriods =
  (): SelectOption<number>[] => [
    { id: 0, value: 0, testId: "reoccurrence-period-0" },
    { id: 1, value: 1, testId: "reoccurrence-period-1" },
    { id: 3, value: 3, testId: "reoccurrence-period-3" },
    { id: 7, value: 7, testId: "reoccurrence-period-7" },
    { id: 14, value: 14, testId: "reoccurrence-period-14" },
    { id: 30, value: 30, testId: "reoccurrence-period-30" },
    { id: 45, value: 45, testId: "reoccurrence-period-45" },
    { id: 60, value: 60, testId: "reoccurrence-period-60" },
    { id: 75, value: 75, testId: "reoccurrence-period-75" },
    { id: 90, value: 90, testId: "reoccurrence-period-90" },
  ];

export const getDefaultRelatedSignalEventsTimePeriod = () =>
  getRelatedSignalEventsTimePeriods().slice(-1)[0];

export const getSelectedPeriod = (period: number) =>
  getRelatedSignalEventsTimePeriods().find((x) => x.id === period) ||
  getDefaultRelatedSignalEventsTimePeriod();

// TODO: Might find a better way to distinguish between issue and suggested issue
export const isSuggestedIssue = (issue: IssueTypes) => !("canEdit" in issue);

type IssuesRoutes = typeof ISSUES_ROUTE | typeof SUGGESTED_ISSUE_RUNS_ROUTE;

export const getBaseAPIRoute = (issue: IssueTypes): IssuesRoutes =>
  isSuggestedIssue(issue) ? SUGGESTED_ISSUE_RUNS_ROUTE : ISSUES_ROUTE;

export const getIssueCombinedID = (issue: IssueTypes): string[] =>
  isSuggestedIssue(issue)
    ? [issue.ID, (issue as SuggestedIssue).updated]
    : [issue.ID];

export const getPopulationString = (population: IssueVehiclePopulation) =>
  population === "atRisk" ? "At-Risk Population" : "Comparison Population";

const getValueFormatter = (
  actions: ChartAction[],
  actionId: ChartActionID,
  optionId: string | undefined
) =>
  actions
    .find((x) => x.id === actionId)
    ?.options?.find((x) => x.id === optionId)?.valueFormatter;

const getTooltipValueFormatter = (
  actions: ChartAction[],
  actionId: ChartActionID,
  optionId: string | undefined
) =>
  actions
    .find((x) => x.id === actionId)
    ?.options?.find((x) => x.id === optionId)?.tooltipValueFormatter;

const dateToTs = (date: string) => toDate(date).getTime();

export const prepareScatteredChartLineDefinitions = (
  data: IssueRepairEfficacyVINTimeline[]
): LegendConfigLabel[] => {
  if (!data || data.length === 0) {
    return [];
  }

  const uniqueValues = getChartValues("codeType", data, [], false);
  const uniqueCodeTypes = [...new Set([...uniqueValues, ""])];

  return uniqueCodeTypes.map((value, idx) => {
    return {
      key: value,
      color: getColor(idx),
      label: value || "Claim",
      shape: value ? "circle" : "triangle",
    } as LegendConfigLabel;
  });
};

export const prepareLineDefinitions = (values: string[]) =>
  values.map((value, idx) => {
    return {
      key: value === null ? "null" : value,
      color: getColor(idx),
      label: value === null ? "None" : value,
    } as YAxisLine;
  });

export const getChartValues = (
  field: string,
  data?: Record<string, any>[],
  comparisonData?: Record<string, any>[],
  numericSort: boolean = true
) => {
  if (
    (!data || data.length === 0) &&
    (!comparisonData || comparisonData.length === 0)
  ) {
    return [];
  }

  const dataValues = data?.map((entry) => entry[field]) || [];
  const compDataValues = comparisonData?.map((entry) => entry[field]) || [];

  const allValues = [...new Set([...dataValues, ...compDataValues])];
  return numericSort ? allValues.sort((a, b) => a - b) : allValues.sort();
};

export const appendTsFromDate = (data?: Record<string, any>[]) => {
  return data?.map((row) => {
    return {
      ts: dateToTs(row.date),
      ...row,
    };
  });
};

export const getKeysAndLabels = (
  type: IssueChartType,
  chart: IssueChart,
  xAxisLabel?: string,
  exposure?: string
) => {
  const defaultVehicleAgeKeysAndLabels = {
    xAxisKey: "exposure",
    xAxisLabel: xAxisLabel ?? "",
    tooltipLabel: xAxisLabel ?? "",
  };
  if (type === "VehicleAge" && exposure === "monthsInService") {
    defaultVehicleAgeKeysAndLabels.tooltipLabel = "Days In Service";
  }

  const defaultDaysSinceClaimKeysAndLabels = {
    xAxisKey: "daysSinceClaim",
    xAxisLabel: xAxisLabel ?? "Days Since Claim",
    tooltipLabel: "Days Since Claim",
  };

  const defaultCalendarDaysKeysAndLabels = {
    xAxisKey: "ts",
    xAxisLabel: xAxisLabel ?? "Date",
    tooltipLabel: "Date",
  };

  const defaultBarKeysAndLabels = {
    xAxisKey: "name",
    xAxisLabel: xAxisLabel ?? "",
    tooltipLabel: "",
  };

  const barCharts = ["RepairEfficacy_ReoccurrenceBar"];

  const repairDateXLabelCharts = [
    "Claims_OccurrencesByCalendarTime",
    "Claims_TopXByCalendarTime",
    "Relationships_ClaimOccurrencesByCalendarTime",
  ];

  const occurrenceDateXLabelCharts = [
    "SignalEvents_OccurrencesByCalendarTime",
    "SignalEvents_RateByCalendarTime",
    "Relationships_SignalEventOccurrencesByCalendarTime",
  ];

  if (barCharts.includes(chart)) {
    return defaultBarKeysAndLabels;
  }

  if (repairDateXLabelCharts.includes(chart)) {
    return { ...defaultCalendarDaysKeysAndLabels, xAxisLabel: "Repair Date" };
  }

  if (occurrenceDateXLabelCharts.includes(chart)) {
    return {
      ...defaultCalendarDaysKeysAndLabels,
      xAxisLabel: "Occurrence Date",
    };
  }

  switch (type) {
    case "VehicleAge":
      return defaultVehicleAgeKeysAndLabels;
    case "CalendarTime":
      return defaultCalendarDaysKeysAndLabels;
    case "DaysSinceClaim":
      return defaultDaysSinceClaimKeysAndLabels;
    default:
      return defaultVehicleAgeKeysAndLabels;
  }
};

export const getXAxisProps = (
  type: IssueChartType,
  data: DataElement[],
  exposure?: string
): XAxisProps => {
  if (type === "VehicleAge") {
    return {
      type: "number",
      domain: ["auto", "auto"],
      tickFormatter: (value: number) =>
        exposure === "monthsInService"
          ? // API returns days in service so convert it to months in service for ticks
            `${formatNumber(value / 30, 0)}`
          : value.toString(),
      // since we use tickFormatter above, X axis tick values can be duplicated, so we have to make them unique
      ticks:
        exposure === "monthsInService" ? getMISTicksFromDIS(data) : undefined,
    };
  }

  if (type === "DaysSinceClaim") {
    return {
      type: "number",
      domain: ["auto", "auto"],
    };
  }

  return generateXAxisTickProps(data);
};

export const getTooltipProps = (type: IssueChartType, tooltipLabel: string) => {
  if (["VehicleAge", "DaysSinceClaim"].includes(type)) {
    return {
      labelFormatter: (value: number) => `${tooltipLabel}: ${value}`,
    };
  }

  return {
    labelFormatter: (unixTime: number) =>
      `${tooltipLabel}: ${format(unixTime, "dd MMMM yy")}`,
  };
};

export const getAxisKeyLabelFromActions = (
  selectedOptions: SelectedChartOptions[],
  actions: ChartAction[],
  actionID: ChartActionID
) => {
  const axisKey = getSelectedOptionId(selectedOptions, actionID);
  const axisValue = getAxisValue(actions, actionID, axisKey);
  const valueFormatter = getValueFormatter(actions, actionID, axisKey);
  const tooltipValueFormatter = getTooltipValueFormatter(
    actions,
    actionID,
    axisKey
  );
  return { axisKey, axisValue, valueFormatter, tooltipValueFormatter };
};

// we expect all values to be numbers except for the xAxisKey's value
export interface ChartDataElement {
  [key: string]: number | string;
}

export interface transformedData {
  chartData: ChartDataElement[];
  yAxisBars: { [key: string]: string };
  splitChartData: ChartDataElement[];
  nameIDMapping: { [key: string]: string };
}

const yAxisBarsAge: Record<string, ageOptions> = {
  "0": "<1 Week",
  "1": "1 Week",
  "2": "2 Weeks",
  "3": "3 Weeks",
  "4": "4+ Weeks",
};

export interface dateLimits {
  limitOld: number;
  limitNew: number;
}

export const weekLimitMap: Record<ageOptions, dateLimits> = {
  "<1 Week": { limitOld: -1, limitNew: 0 },
  "1 Week": { limitOld: -2, limitNew: -1 },
  "2 Weeks": { limitOld: -3, limitNew: -2 },
  "3 Weeks": { limitOld: -4, limitNew: -3 },
  "4+ Weeks": { limitOld: -5214, limitNew: -4 }, // 100 years ago - 4 weeks ago
};

const formatXAxis = (
  chartData: ChartDataElement[],
  groupBy: GroupByIssuesEnum | GroupBySuggestedIssuesEnum
) => {
  // remove empty chart data (such that only have xAxisKey and no data elements)
  const onlyHasXAxisKeys = chartData.every(
    (group) =>
      Object.keys(group).length === 1 &&
      group.hasOwnProperty(DASHBOARD_GROUP_BY_KEY)
  );

  if (onlyHasXAxisKeys) {
    return [];
  }

  // format empty values
  chartData.forEach((group) => {
    if (group[DASHBOARD_GROUP_BY_KEY] === "") {
      if (groupBy === "assignedGroupID") {
        group[DASHBOARD_GROUP_BY_KEY] = EMPTY_GROUP_CHART_LABEL;
      } else {
        group[DASHBOARD_GROUP_BY_KEY] = EMPTY_CHART_LABEL;
      }
    }
  });

  // handle age label formatting
  if (groupBy === "statusUpdatedAt") {
    chartData = chartData.map((item) => {
      return {
        ...item,
        // @ts-ignore
        xAxisKey: yAxisBarsAge[item.xAxisKey] || item.xAxisKey,
      };
    });
  }
  return chartData;
};

const findOrCreateGroup = (
  targetChartData: ChartDataElement[],
  groupByAttributeValue: string
) => {
  let group = targetChartData.find(
    (el) => el[DASHBOARD_GROUP_BY_KEY] === groupByAttributeValue
  );
  if (!group) {
    group = {
      xAxisKey: groupByAttributeValue,
    };
    targetChartData.push(group);
  }
  return group;
};

export const getMeasureLabel = (
  valueType: ValueType,
  measureKey: string,
  lookbackWindow: string | undefined,
  defaultMeasureLabel: string
) => {
  const percentageLabels = Object.keys(MEASURE_PERCENTAGE_LABELS);
  let modifiedMeasureLabel = defaultMeasureLabel;
  if (valueType === "percentage" && percentageLabels.includes(measureKey)) {
    modifiedMeasureLabel = MEASURE_PERCENTAGE_LABELS[measureKey];
  }
  if (lookbackWindow !== undefined && parseInt(lookbackWindow) !== 0) {
    const lookbackAction = ISSUES_CHART_ACTIONS.find(
      ({ id }) => id === "lookbackWindow"
    );
    if (lookbackAction) {
      const currentLookbackOption = lookbackAction.options?.find(
        ({ id }) => id === lookbackWindow
      );
      const previousLookbackId = (parseInt(lookbackWindow) - 1).toString();
      const previousLookbackOption = lookbackAction.options?.find(
        ({ id }) => id === previousLookbackId
      );
      if (currentLookbackOption && previousLookbackOption) {
        const currentLookbackLabel = (
          currentLookbackOption.label as string
        ).toLocaleLowerCase();
        const previousLookbackLabel = (
          previousLookbackOption.label as string
        ).toLocaleLowerCase();
        modifiedMeasureLabel = `${modifiedMeasureLabel} in ${currentLookbackLabel}, compared to ${previousLookbackLabel}`;
      }
    }
  }

  return modifiedMeasureLabel;
};

export const transformIssuesOverviewData = (
  data: IssuesOverview[] | undefined,
  bucketBy: BucketByIssuesEnum,
  groupBy: GroupByIssuesEnum,
  measureKey: string,
  measureLabel: string,
  splitByIssueSource: boolean,
  valueType: ValueType
) => {
  const result: transformedData = {
    chartData: [],
    yAxisBars: {},
    splitChartData: [],
    nameIDMapping: {},
  };

  data?.forEach((item) => {
    let {
      groupByAttributeValue,
      groupByAttributeID,
      bucketByAttributeValue,
      bucketByAttributeID,
      count,
      origin,
    } = item;

    if (measureKey !== "count" && valueType === "percentage") {
      count = formatPercentValue(count);
    }

    // we eliminate the possibility of having null as a key
    bucketByAttributeValue = String(bucketByAttributeValue);

    // values greater than 4 should default to 4 in case of age
    if (bucketBy === "statusUpdatedAt") {
      if (isNumeric(bucketByAttributeValue)) {
        bucketByAttributeValue =
          Number(bucketByAttributeValue) > 4 ? "4" : bucketByAttributeValue;
      }
    } else if (groupBy === "statusUpdatedAt") {
      if (isNumeric(groupByAttributeValue)) {
        groupByAttributeValue =
          Number(groupByAttributeValue) > 4 ? "4" : groupByAttributeValue;
      }
    }

    result.nameIDMapping[groupByAttributeValue] = groupByAttributeID;
    result.nameIDMapping[bucketByAttributeValue] = bucketByAttributeID;

    let targetChartData = result.chartData;
    if (splitByIssueSource) {
      targetChartData =
        origin === "ManuallyCreated" ? result.chartData : result.splitChartData;
    }

    let group = findOrCreateGroup(targetChartData, groupByAttributeValue);

    if (splitByIssueSource) {
      // we need to make sure to have all xAxisKeys in both chartData and splitChartData
      let otherChartData;
      if (targetChartData === result.chartData) {
        otherChartData = result.splitChartData;
      } else {
        otherChartData = result.chartData;
      }

      findOrCreateGroup(otherChartData, groupByAttributeValue);
    }

    group[bucketByAttributeValue] = count;

    if (bucketByAttributeValue === "") {
      if (bucketBy === "assignedGroupID") {
        result.yAxisBars[bucketByAttributeValue] = EMPTY_GROUP_CHART_LABEL;
      } else {
        result.yAxisBars[bucketByAttributeValue] = EMPTY_CHART_LABEL;
      }
    } else {
      result.yAxisBars[bucketByAttributeValue] = bucketByAttributeValue;
    }
  });

  // format values on x axis
  result.chartData = formatXAxis(result.chartData, groupBy);
  if (splitByIssueSource) {
    result.splitChartData = formatXAxis(result.splitChartData, groupBy);
  }

  // format values on y axis
  if (bucketBy === "statusUpdatedAt") {
    result.yAxisBars = Object.fromEntries(
      Object.entries(result.yAxisBars).map(([key]) => [key, yAxisBarsAge[key]])
    );
  } else if (bucketBy === "none") {
    // we need to handle the null bucketByAttributeValue
    result.yAxisBars["null"] = measureLabel;
  }

  return result;
};

export const transformSuggestedIssuesOverviewData = (
  data: SuggestedIssuesOverview[] | undefined,
  bucketBy: BucketByIssuesEnum | BucketBySuggestedIssuesEnum,
  groupBy: GroupByIssuesEnum | GroupBySuggestedIssuesEnum,
  measureKey: string,
  measureLabel: string,
  valueType: ValueType
) => {
  const result: transformedData = {
    chartData: [],
    yAxisBars: {},
    splitChartData: [],
    nameIDMapping: {},
  };

  data?.forEach((item) => {
    let {
      groupByAttributeValue,
      groupByAttributeID,
      bucketByAttributeValue,
      bucketByAttributeID,
      count,
    } = item;

    if (measureKey !== "count" && valueType === "percentage") {
      count = formatPercentValue(count);
    }

    // we eliminate the possibility of having null as a key
    bucketByAttributeValue = String(bucketByAttributeValue);

    // values greater than 4 should default to 4 in case of age
    if (bucketBy === "statusUpdatedAt") {
      if (isNumeric(bucketByAttributeValue)) {
        bucketByAttributeValue =
          Number(bucketByAttributeValue) > 4 ? "4" : bucketByAttributeValue;
      }
    } else if (groupBy === "statusUpdatedAt") {
      if (isNumeric(groupByAttributeValue)) {
        groupByAttributeValue =
          Number(groupByAttributeValue) > 4 ? "4" : groupByAttributeValue;
      }
    }

    result.nameIDMapping[groupByAttributeValue] = groupByAttributeID;
    result.nameIDMapping[bucketByAttributeValue] = bucketByAttributeID;

    let group = findOrCreateGroup(result.chartData, groupByAttributeValue);

    group[bucketByAttributeValue] = count;

    if (bucketByAttributeValue === "") {
      if (bucketBy === "assignedGroupID") {
        result.yAxisBars[bucketByAttributeValue] = EMPTY_GROUP_CHART_LABEL;
      } else {
        result.yAxisBars[bucketByAttributeValue] = EMPTY_CHART_LABEL;
      }
    } else {
      result.yAxisBars[bucketByAttributeValue] = bucketByAttributeValue;
    }
  });

  // format values on x axis
  result.chartData = formatXAxis(result.chartData, groupBy);

  // format values on y axis
  if (bucketBy === "statusUpdatedAt") {
    result.yAxisBars = Object.fromEntries(
      Object.entries(result.yAxisBars).map(([key]) => [key, yAxisBarsAge[key]])
    );
  } else if (bucketBy === "none") {
    // we need to handle the null bucketByAttributeValue
    result.yAxisBars["null"] = measureLabel;
  }

  return result;
};

export const getDefaultIssueFilter = (): FilterGroupState => {
  const {
    pages: { issues },
  } = config.get();
  return issues?.defaultFilters || DEFAULT_ISSUES_FILTER;
};

export const getMaxValue = (data: ChartDataElement[]): number => {
  let maxValue = 0;

  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key === DASHBOARD_GROUP_BY_KEY || !isNumeric(item[key])) {
        return;
      }

      if (Number(item[key]) > maxValue) {
        maxValue = Number(item[key]);
      }
    });
  });

  return maxValue;
};

export const getLongestValue = (data: ChartDataElement[]): number => {
  let longestValue = 0;

  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key === DASHBOARD_GROUP_BY_KEY || !isNumeric(item[key])) {
        return;
      }

      if (
        Number(item[key]).toString().length > longestValue.toString().length
      ) {
        longestValue = Number(item[key]);
      }
    });
  });

  return longestValue;
};

export const getFilterFromBars = (
  filterValues: string[],
  filterAttribute: string,
  nameIDMapping: { [key: string]: string }
): FilterGroupState | undefined => {
  if (filterValues.length <= 0) {
    return undefined;
  }

  // re-map empty bar values
  filterValues = filterValues.map((val) =>
    val === EMPTY_CHART_LABEL ? "" : val
  );

  const filterGroup: FilterGroupState = {
    id: `group-0`,
    type: "group",
    anyAll: "all",
    children: [],
  };

  if (filterAttribute === "assignedGroupID") {
    handleAssignedGroupFilter(
      filterGroup,
      filterAttribute,
      filterValues,
      nameIDMapping
    );
  } else if (filterAttribute === "statusUpdatedAt") {
    handleStatusUpdatedAtFilter(filterGroup, filterAttribute, filterValues);
  } else {
    handleDefaultFilter(filterGroup, filterAttribute, filterValues);
  }

  return filterGroup;
};

const handleAssignedGroupFilter = (
  filterGroup: FilterGroupState,
  filterAttribute: string,
  filterValues: string[],
  nameIDMapping: { [key: string]: string } | undefined
) => {
  const newFilterGroup: FilterGroupState = {
    type: "group",
    id: `group-${randomID()}`,
    anyAll: "any",
    children: [],
  };

  const hasEmptyGroup = filterValues.includes(EMPTY_GROUP_CHART_LABEL);
  const nonEmptyValues = filterValues.filter(
    (val) => val !== EMPTY_GROUP_CHART_LABEL
  );

  if (hasEmptyGroup) {
    newFilterGroup.children.push({
      type: "row",
      id: `row-${randomID()}`,
      attribute: filterAttribute,
      operator: FilterOperator.IS_EMPTY,
      values: ["null"],
    });
  }

  if (nonEmptyValues.length > 0) {
    const mappedValues = nameIDMapping
      ? nonEmptyValues.map((val) => nameIDMapping[val] || val)
      : nonEmptyValues;

    newFilterGroup.children.push({
      type: "row",
      id: `row-${randomID()}`,
      attribute: filterAttribute,
      operator: FilterOperator.IN,
      values: mappedValues,
    });
  }

  filterGroup.children.push(newFilterGroup);
};

const handleStatusUpdatedAtFilter = (
  filterGroup: FilterGroupState,
  filterAttribute: string,
  filterValues: string[]
) => {
  const newFilterGroup: FilterGroupState = {
    type: "group",
    id: `group-${randomID()}`,
    anyAll: "any",
    children: [],
  };

  filterValues.forEach((value, index) => {
    const { limitOld, limitNew } = weekLimitMap[value as ageOptions];
    const startDate = add(new Date(), { weeks: limitOld }).toISOString();
    const endDate = add(new Date(), { weeks: limitNew }).toISOString();

    newFilterGroup.children.push({
      type: "row",
      id: `row-${randomID()}`,
      attribute: filterAttribute,
      operator: FilterOperator.BETWEEN,
      values: [startDate, endDate],
    });
  });

  filterGroup.children.push(newFilterGroup);
};

const handleDefaultFilter = (
  filterGroup: FilterGroupState,
  filterAttribute: string,
  filterValues: string[]
) => {
  filterGroup.children.push({
    type: "row",
    id: `row-${randomID()}`,
    attribute: filterAttribute,
    operator: FilterOperator.IN,
    values: filterValues,
  });
};

export const getFilterFromColumns = (
  selectedColumns: { [key: string]: string[] },
  filterGroupAttribute: string,
  filterBucketAttribute: string,
  nameIDMapping: { [key: string]: string }
): FilterGroupState | undefined => {
  if (Object.keys(selectedColumns).length <= 0) {
    return undefined;
  }

  const filterGroup: FilterGroupState = {
    id: `group-0`,
    type: "group",
    anyAll: "any",
    children: [],
  };

  Object.entries(selectedColumns).forEach(
    ([filterGroupValue, filterBucketValues]) => {
      if (filterBucketValues.length <= 0) {
        return;
      }

      const newFilterGroup: FilterGroupState = {
        type: "group",
        id: `group-${randomID()}`,
        anyAll: "all",
        children: [],
      };

      // re-map empty group value
      const mappedFilterGroupValue =
        filterGroupValue === EMPTY_CHART_LABEL ? "" : filterGroupValue;

      if (filterGroupAttribute === "assignedGroupID") {
        handleAssignedGroupRowFilter(
          newFilterGroup,
          filterGroupAttribute,
          mappedFilterGroupValue,
          nameIDMapping
        );
      } else if (filterGroupAttribute === "statusUpdatedAt") {
        handleStatusUpdatedAtRowFilter(
          newFilterGroup,
          filterGroupAttribute,
          mappedFilterGroupValue
        );
      } else {
        handleDefaultRowFilter(
          newFilterGroup,
          filterGroupAttribute,
          mappedFilterGroupValue
        );
      }

      // if cell axis filtering is none, we don't need to filter any further
      if (filterBucketAttribute === "none") {
        filterGroup.children.push(newFilterGroup);
        return;
      }

      // re-map empty bucket values
      const mappedFilterBucketValues = filterBucketValues.map((v) =>
        v === EMPTY_CHART_LABEL ? "" : v
      );

      if (filterBucketAttribute === "assignedGroupID") {
        handleAssignedGroupFilter(
          newFilterGroup,
          filterBucketAttribute,
          mappedFilterBucketValues,
          nameIDMapping
        );
      } else if (filterBucketAttribute === "statusUpdatedAt") {
        handleStatusUpdatedAtFilter(
          newFilterGroup,
          filterBucketAttribute,
          mappedFilterBucketValues
        );
      } else {
        handleDefaultFilter(
          newFilterGroup,
          filterBucketAttribute,
          mappedFilterBucketValues
        );
      }
      filterGroup.children.push(newFilterGroup);
    }
  );

  return filterGroup;
};

const handleAssignedGroupRowFilter = (
  filterGroup: FilterGroupState,
  filterAttribute: string,
  filterValue: string,
  nameIDMapping: { [key: string]: string } | undefined
) => {
  if (filterValue === EMPTY_GROUP_CHART_LABEL) {
    filterGroup.children.push({
      type: "row",
      id: `row-${randomID()}`,
      attribute: filterAttribute,
      operator: FilterOperator.IS_EMPTY,
      values: ["null"],
    });
    return filterGroup;
  }

  const mappedValue = nameIDMapping ? nameIDMapping[filterValue] : filterValue;
  filterGroup.children.push({
    type: "row",
    id: `row-${randomID()}`,
    attribute: filterAttribute,
    operator: FilterOperator.IN,
    values: [mappedValue],
  });

  return filterGroup;
};

const handleStatusUpdatedAtRowFilter = (
  filterGroup: FilterGroupState,
  filterAttribute: string,
  filterValue: string
) => {
  const { limitOld, limitNew } = weekLimitMap[filterValue as ageOptions];
  const startDate = add(new Date(), { weeks: limitOld }).toISOString();
  const endDate = add(new Date(), { weeks: limitNew }).toISOString();

  filterGroup.children.push({
    type: "row",
    id: `row-${randomID()}`,
    attribute: filterAttribute,
    operator: FilterOperator.BETWEEN,
    values: [startDate, endDate],
  });
};

const handleDefaultRowFilter = (
  filterGroup: FilterGroupState,
  filterAttribute: string,
  filterValue: string
) => {
  filterGroup.children.push({
    type: "row",
    id: `row-${randomID()}`,
    attribute: filterAttribute,
    operator: FilterOperator.IN,
    values: [filterValue],
  });
};
