import FormSection from "pages/SignalEventStudio/Common/FormSection";

import FilterBuilder from "features/ui/Filters/FilterBuilder/FilterBuilder";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  filterBuilderState?: FilterGroupState;
  filtersSchema: SchemaEntry[];
  onChange: (state: FilterGroupState) => void;
  disabled?: boolean;
  className?: string;
}

const CustomAttributesFiltersSection = ({
  filterBuilderState,
  filtersSchema,
  onChange,
  disabled,
  className,
}: Props) => (
  <FormSection
    title="Custom Attribute Filters"
    text="Filter the event data further using custom attributes to capture just
          the event occurrences you are looking for"
    className={className}
  >
    <FilterBuilder
      filterBuilderState={filterBuilderState}
      filtersSchema={filtersSchema}
      onChange={onChange}
      disabled={disabled}
      inline
    />
  </FormSection>
);

export default CustomAttributesFiltersSection;
