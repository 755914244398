import { RequestParams } from "duck/agents/ClaimAnalytics/types";
import {
  setByVehicleAgeChartOptions,
  setClaimsChartOptions,
  setSelectedGroupByAttributeFromId,
  updateClaimsFilter,
  updateSignalEventOccurrencesFilter,
  updateSignalEventOccurrencesWindowSize,
  updateVehiclesFilter,
} from "duck/agents/ClaimAnalytics/utils/handlers";
import { navigateToTab } from "duck/agents/common/utils/handlers/queryStringNavigation";
import getTheRespondToUserTool from "duck/agents/common/utils/tools/getRespondToUserTool";
import getUpdateClaimsFilterTool from "duck/agents/common/utils/tools/getUpdateClaimsFilterTool";
import getUpdateVehiclesFilterTool from "duck/agents/common/utils/tools/getUpdateVehiclesFilterTool";
import { StructuredTool } from "@langchain/core/tools";

import getGoToAssociatedSignalEventsTabTool from "./getGoToAssociatedSignalEventsTabTool";
import getGoToAssociatedVehiclesTabTool from "./getGoToAssociatedVehiclesTabTool";
import getGoToClaimsByVehiclesAgeTabTool from "./getGoToClaimsByVehiclesAgeTabTool";
import getGoToClaimsTabTool from "./getGoToClaimsTabTool";
import getGoToTopContributorsTabTool from "./getGoToTopContributorsTabTools";

let respondToUserTool: StructuredTool;

/**
 * @summary Get the tools for the claim analytics agent.
 * The tools are specific to claim analytics, and are bound to the UI handlers
 * for claim analytics.
 * @param params The data from the UI layer that is necessary for the tools to function.
 * @returns The full set of tools for the claim analytics agent.
 */
const getTools = (params: RequestParams): StructuredTool[] => {
  respondToUserTool = getTheRespondToUserTool(
    params.uiHandlers.setAgentResponse
  );
  return [
    getUpdateClaimsFilterTool(updateClaimsFilter),
    getUpdateVehiclesFilterTool(updateVehiclesFilter),
    getGoToClaimsTabTool(
      navigateToTab,
      setClaimsChartOptions,
      params.claimsChartOptions
    ),
    getGoToClaimsByVehiclesAgeTabTool(
      navigateToTab,
      setByVehicleAgeChartOptions,
      params.byVehicleAgeChartOptions
    ),
    getGoToTopContributorsTabTool(
      navigateToTab,
      setSelectedGroupByAttributeFromId
    ),
    getGoToAssociatedVehiclesTabTool(navigateToTab),
    getGoToAssociatedSignalEventsTabTool(
      navigateToTab,
      updateSignalEventOccurrencesFilter,
      updateSignalEventOccurrencesWindowSize
    ),
    respondToUserTool,
  ];
};

export const getRespondToUserTool = (): StructuredTool => respondToUserTool;

export default getTools;
