import { useState } from "react";
import { Stack } from "@mui/material";

import DuckMessages from "./DuckMessages";
import DuckResetMemory from "./DuckResetMemory";
import DuckTextInput from "./DuckTextInput";
import { useThreadId } from "./hooks";

const DuckOpenContent = () => {
  const [loading, setLoading] = useState(false);
  const [pendingAction, setPendingAction] = useState(false);

  const { threadId, resetThreadId } = useThreadId();

  return (
    <Stack height="100%" paddingX={0.5} display="flex" flexGrow={1}>
      <Stack
        display="flex"
        direction="column"
        flexGrow={1}
        overflow="hidden"
        height="100%"
      >
        <DuckMessages
          loading={loading}
          pendingAction={pendingAction}
          setPendingAction={setPendingAction}
        />
      </Stack>
      <Stack className="py-1" direction="row" spacing={0.5} flexGrow={0}>
        <DuckResetMemory enabled={!loading} resetThreadId={resetThreadId} />
        <DuckTextInput
          threadId={threadId}
          loading={loading}
          setLoading={setLoading}
          setPendingAction={setPendingAction}
        />
      </Stack>
    </Stack>
  );
};

export default DuckOpenContent;
