import { useState } from "react";
import Skeleton from "react-loading-skeleton";

import { useListVehicleValues } from "shared/api/vehicles/hooks";
import { NO_DATA } from "shared/constants";

import CustomEventPreview from "pages/SignalEventStudio/Common/CustomEventPreview";
import { MAX_VEHICLES_PREVIEW } from "pages/SignalEventStudio/constants";
import {
  getCustomSignalEventRequestParams,
  SignalEventStudioState,
} from "pages/SignalEventStudio/utils";

import APIError from "features/ui/APIError";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { RowData } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import SignalEventPreviewVehicleSummary from "./SignalEventPreviewVehicleSummary";

interface Props {
  signalEventStudioState: SignalEventStudioState;
  vehiclesFilterSortState: UseFilterSortState;
  dateFilterSortState: UseFilterSortState;
  dateType: DataType;
  className?: string;
}

const SignalEventPreviewData = ({
  signalEventStudioState,
  vehiclesFilterSortState,
  dateFilterSortState,
  dateType,
  className,
}: Props) => {
  const [selectedRow, setSelectedRow] = useState<RowData>();

  const {
    schema,
    customAttributes,
    selectedEventTypeOption,
    customAttributeFilterBuilderState,
    selectedDownsamplingOption,
    filterBuilderState,
  } = signalEventStudioState;

  const { data, isLoading, error } = useListVehicleValues({
    filter: getFiltersQuery(vehiclesFilterSortState.filters),
    limit: MAX_VEHICLES_PREVIEW,
    fieldName: "VIN",
  });

  if (isLoading) {
    return <Skeleton height={300} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data || data.distinctValues.length === 0) {
    return NO_DATA;
  }

  return (
    <>
      <SignalEventPreviewVehicleSummary
        signalEventStudioState={signalEventStudioState}
        VINs={data.distinctValues as string[]}
        dateFilters={dateFilterSortState.filters}
        dateType={dateType}
        setSelectedRow={setSelectedRow}
        className={className}
      />
      {selectedRow && (
        <CustomEventPreview
          schema={schema}
          customAttributes={customAttributes}
          selectedVIN={selectedRow.VIN}
          requestBody={getCustomSignalEventRequestParams(
            [selectedRow.VIN!],
            selectedEventTypeOption,
            filterBuilderState,
            customAttributeFilterBuilderState,
            customAttributes,
            selectedDownsamplingOption
          )}
          className={className}
        />
      )}
    </>
  );
};

export default SignalEventPreviewData;
