import {
  ByVehicleAgeChartOptionStrings,
  ClaimsChartOptionStrings,
  NonEmptyStringArray,
  PageState,
} from "duck/agents/ClaimAnalytics/types";
import qs from "qs";

import { randomID } from "shared/utils";

import {
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import { RELATES_FILTER_KEY } from "pages/ClaimAnalytics/tabPages/AssociatedSignalEvents";
import {
  DEFAULT_GROUP_BY_SELECT_OPTION,
  GROUP_BY_ATTRIBUTE_LOCAL_STORAGE_KEY,
} from "pages/ClaimAnalytics/tabPages/TopContributors/TopContributors";

import { ChartAction } from "features/ui/charts/ChartActions";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { DEFAULT_RELATES_FILTER } from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import {
  getPageKeyWithVersion,
  getStateFromLocalStorage,
} from "features/ui/Filters/utils";
import { Option, SelectOption } from "features/ui/Select";

import * as config from "config/config";

import { DUCK_VISIBILITY_KEY, LANGCHAIN_THREAD_ID_KEY } from "./constants";

/**
 * If all of the Duck env vars exist, then we support Duck.
 * @returns True if the current environment supports the Duck UI; false if not.
 */
export const hasAllEnvVarsForViaDuck = (): boolean =>
  Boolean(
    process.env.REACT_APP_OPENAI_API_KEY &&
      process.env.REACT_APP_OPENAI_API_ORG &&
      process.env.REACT_APP_LANGCHAIN_ENDPOINT &&
      process.env.REACT_APP_LANGCHAIN_PROJECT &&
      process.env.REACT_APP_LANGCHAIN_API_KEY &&
      process.env.REACT_APP_LANGCHAIN_TRACING_V2 &&
      process.env.REACT_APP_LANGCHAIN_CALLBACKS_BACKGROUND
  );

type NonEmptyStringArrayAssertion = (
  arr: unknown
) => asserts arr is NonEmptyStringArray;

/**
 * @summary Asserts that the parameter is a non-empty array of strings.
 * @param arr The variable to check.
 * @throws An error if the parameter array is not an array, is empty,
 * or contains anything except strings.
 */
export const assertNonEmptyStringArray: NonEmptyStringArrayAssertion = (
  arr
) => {
  if (!Array.isArray(arr)) {
    throw new Error(`${arr} must be an array.`);
  }
  if (arr.length === 0) {
    throw new Error("Array must contain at least one element.");
  }
  if (arr.some((element) => typeof element !== "string")) {
    throw new Error("Array must contain only strings.");
  }
};

/**
 * @summary Converts an array of SelectOptions to a non-empty array of strings.
 * @param options The array of SelectOptions to convert
 * @returns A non-empty array of strings containing the SelectOptions' ids.
 * @throws An error if the parameter array is empty.
 */
export const toNonEmptyStringArray = (
  options: SelectOption<Option>[]
): NonEmptyStringArray => {
  const result = options.map((option) => String(option.id));
  assertNonEmptyStringArray(result);
  return result;
};

const extractFromActions = (
  actions: ChartAction<Option>[],
  actionId: string
): NonEmptyStringArray => {
  const action = actions.find((action) => action.id === actionId);
  if (!action || !action.options) {
    throw new Error(`Could not find action with options with id ${actionId}`);
  }

  return toNonEmptyStringArray(action.options);
};

export const getClaimsChartOptionStrings = (
  actions: ChartAction<Option>[]
): ClaimsChartOptionStrings => ({
  y: extractFromActions(actions, "y"),
});

export const getByVehicleAgeChartOptionStrings = (
  actions: ChartAction<Option>[]
): ByVehicleAgeChartOptionStrings => ({
  y: extractFromActions(actions, "y"),
  x: extractFromActions(actions, "x"),
  granularity: extractFromActions(actions, "granularity"),
  exposure: extractFromActions(actions, "exposure"),
});

/**
 * It would be nice to use the useCustomLocalStorageState hook here, but this function
 * needs to be called when the user submits at utterance to the agent and it would be
 * awkward to call a hook at that time. Directly retrieving the value from localStorage
 * is simple enough.
 *
 * We have to say "T extends unknown" so that React knows that T is a generic type parameter
 * rather than a JSX element.
 */
const getFromLocalStorage = <T extends unknown>(
  key: string,
  defaultValue: T
): T => {
  try {
    const valueString = localStorage.getItem(getPageKeyWithVersion(key));
    const value: T = valueString ? JSON.parse(valueString) : defaultValue;
    return value;
  } catch (error) {
    return defaultValue;
  }
};

const getSelectedGroupByAttributeId = (): string => {
  const selectedGroupByAttribute = getFromLocalStorage(
    GROUP_BY_ATTRIBUTE_LOCAL_STORAGE_KEY,
    DEFAULT_GROUP_BY_SELECT_OPTION
  );
  return String(selectedGroupByAttribute.id);
};

interface SignalEventOccurrencesData {
  signalEventOccurrencesFilterQueryString: string;
  signalEventOccurrencesWindowSize: number;
}

const getSignalEventOccurrencesData = (): SignalEventOccurrencesData => {
  const {
    pages: { signalEventsAnalytics },
  } = config.get();

  const defaultSignalEventFilters = filterStateToFilterGroupState(
    signalEventsAnalytics?.defaultSignalEventFilters
  );

  const defaultAppliedFilters = defaultSignalEventFilters
    ? {
        ...DEFAULT_RELATES_FILTER,
        filters: defaultSignalEventFilters,
      }
    : DEFAULT_RELATES_FILTER;

  const relatesFilter = getFromLocalStorage(
    RELATES_FILTER_KEY,
    defaultAppliedFilters
  );

  return {
    signalEventOccurrencesFilterQueryString: getFiltersQuery(
      relatesFilter?.filters
    ),
    signalEventOccurrencesWindowSize: +relatesFilter?.options?.windowSize,
  };
};

/**
 * getPageState obtains the current page state so that it can be passed to the agent.
 * The data returned by this function is not static, and is updated when the user
 * navigates to a different tab or changes the filters. For this reason, we must obtain
 * it at the time that the agent is called.
 *
 * @returns The current page state.
 */
export const getPageState = (): PageState => {
  const { tab } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const {
    pages: { claimAnalytics },
  } = config.get();

  const claimsPageKeyWithVersion = getPageKeyWithVersion(CLAIMS_PAGE_KEY);
  const defaultClaimFilters = filterStateToFilterGroupState(
    claimAnalytics?.defaultClaimFilters
  );
  const claimFilterSortState = getStateFromLocalStorage(
    claimsPageKeyWithVersion,
    defaultClaimFilters
  );

  const vehiclesPageKeyWithVersion = getPageKeyWithVersion(VEHICLES_PAGE_KEY);
  const defaultVehicleFilters = filterStateToFilterGroupState(
    claimAnalytics?.defaultVehicleFilters
  );
  const vehiclesFilterSortState = getStateFromLocalStorage(
    vehiclesPageKeyWithVersion,
    defaultVehicleFilters
  );

  const signalEventOccurrencesData = getSignalEventOccurrencesData();

  return {
    claimsFilterQueryString: getFiltersQuery(claimFilterSortState.filters),
    tab: tab ? String(tab) : "",
    vehiclesFilterQueryString: getFiltersQuery(vehiclesFilterSortState.filters),
    selectedGroupByAttributeId: getSelectedGroupByAttributeId(),
    signalEventOccurrencesFilterQueryString:
      signalEventOccurrencesData.signalEventOccurrencesFilterQueryString,
    signalEventOccurrencesWindowSize:
      signalEventOccurrencesData.signalEventOccurrencesWindowSize,
  };
};

export const getInitialVisibility = (): boolean => {
  let initialVisibility = false;
  if (sessionStorage) {
    const visibilityFromStorage = sessionStorage.getItem(DUCK_VISIBILITY_KEY);
    if (visibilityFromStorage) {
      initialVisibility = true;
    }
  }

  return initialVisibility;
};

export const persistVisibility = (open: boolean) => {
  if (sessionStorage) {
    if (open) {
      sessionStorage.setItem(DUCK_VISIBILITY_KEY, "true");
    } else {
      sessionStorage.removeItem(DUCK_VISIBILITY_KEY);
    }
  }
};

export const getInitialThreadId = (): string => {
  let initialThreadId = randomID();
  if (sessionStorage) {
    const threadIdFromStorage = sessionStorage.getItem(LANGCHAIN_THREAD_ID_KEY);
    if (threadIdFromStorage) {
      initialThreadId = threadIdFromStorage;
    } else {
      sessionStorage.setItem(LANGCHAIN_THREAD_ID_KEY, initialThreadId);
    }
  }

  return initialThreadId;
};

export const getDuckHeight = (
  fillVerticalSpace: boolean,
  isDuckVisible: boolean
): string | undefined => {
  if (!isDuckVisible) {
    return undefined;
  }
  return fillVerticalSpace ? "100%" : "400px";
};
