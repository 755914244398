export const MODELSPEC = {
  modelName: "gpt-4o",
  temperature: 0.2,
  modelKwargs: {
    seed: 22,
  },
};

export const LC_ENDPOINT = process.env.REACT_APP_LANGCHAIN_ENDPOINT;
export const LC_API_KEY = process.env.REACT_APP_LANGCHAIN_API_KEY;
export const LC_PROJECT_NAME = process.env.REACT_APP_LANGCHAIN_PROJECT;
export const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
