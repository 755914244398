import { useMemo } from "react";
import qs from "qs";
import { generatePath, Link } from "react-router-dom";

import { getSortFilter } from "shared/api/utils";
import { FailureModeEvent } from "shared/api/v0_failureModes/api";
import { useFailureModeEvents } from "shared/api/v0_failureModes/hooks";
import {
  FAILURE_MODE_EVENT_TYPE_FILTER,
  FAILURE_MODE_EVENT_VIN_FILTER,
} from "shared/filterDefinitions";
import { SortBy } from "shared/types";
import { toTitleCase } from "shared/utils";

import APIError from "features/ui/APIError";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  getFilterGroupStateTopLevelRowAttributes,
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { hasSomeFiltersAppliedFilterGroupState } from "features/ui/Filters/utils";
import Section from "features/ui/Section";
import SectionTitle from "features/ui/SectionTitle";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell";

import { routes } from "services/routes";

import FailureModeEventTypeBadge from "./FailureModeEventTypeBadge";

const RESULTS_PER_PAGE = 10;
const DEFAULT_COLUMNS = [
  "VIN",
  "eventType",
  "eventSourceType",
  "eventTimestamp",
];

const VIN_VIEW_HISTORY_TAB = 1;

const DEFAULT_SORT: SortBy = { eventTimestamp: "desc" };

const formatRow = (vehicleRecord: FailureModeEvent) => {
  const search = { tab: VIN_VIEW_HISTORY_TAB };
  const pathname = generatePath(routes.vinView, {
    vin: encodeURIComponent(vehicleRecord.VIN),
  });
  return {
    ...vehicleRecord,
    VIN: (
      <Link
        to={{ pathname, search: qs.stringify(search) }}
        className="text-metabase-blue hover:underline"
      >
        {vehicleRecord.VIN}
      </Link>
    ),
    eventType: <FailureModeEventTypeBadge type={vehicleRecord.eventType} />,
    eventSourceType: <>{toTitleCase(vehicleRecord.eventSourceType ?? "")}</>,
  };
};

interface Props {
  columns?: string[];
  failureModeID: string;
  parentFilters: FilterGroupState;
}

const FailureModeEvents = ({
  columns = DEFAULT_COLUMNS,
  failureModeID,
  parentFilters,
}: Props) => {
  const pageKey = `failure_mode_events_${failureModeID}`;

  let schemaDefault: SchemaEntry[] = [
    {
      label: "VIN",
      accessor: "VIN",
      dataType: DataType.JSX,
      filter: FAILURE_MODE_EVENT_VIN_FILTER(failureModeID),
    },
    {
      label: "Date",
      accessor: "eventTimestamp",
      dataType: DataType.DATE,
    },
    {
      label: "Type",
      accessor: "eventType",
      dataType: DataType.JSX,
      filter: FAILURE_MODE_EVENT_TYPE_FILTER(failureModeID),
    },
    {
      label: "Source Type",
      accessor: "eventSourceType",
      dataType: DataType.JSX,
    },
  ];

  const schema = schemaDefault.filter(({ accessor }: SchemaEntry) =>
    columns.includes(accessor)
  );

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const {
    manageOnFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey,
    defaultSort: DEFAULT_SORT,
    disableUsingQuery: true,
  });

  const combinedFilters = mergeFilterGroupStates(filters, parentFilters);

  const { data, isLoading, headers, error, ...paginationData } =
    useFailureModeEvents({
      id: failureModeID,
      limit: RESULTS_PER_PAGE,
      filter: getFiltersQuery(combinedFilters),
      sort: getSortFilter(sort),
    });

  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data]);

  const hasFilters = hasSomeFiltersAppliedFilterGroupState(filters);

  return (
    <Section testId="failure-mode-events">
      <SectionTitle>Failure Mode Events</SectionTitle>
      {hasFilters && (
        <div className="flex space-x-3 items-center mb-3 mt-3">
          <FiltersOverview
            filters={filters}
            filtersToHide={getFilterGroupStateTopLevelRowAttributes(
              parentFilters
            )}
            tableSchema={schema}
            onFiltersReset={resetFilters}
          />
        </div>
      )}
      {!error && (
        <PaginatedTable
          isLoading={isLoading}
          loadingRows={RESULTS_PER_PAGE}
          data={formattedData}
          schema={schema}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFilterChange={manageOnFilterChange}
          filters={filters}
          pageKey={pageKey}
          {...paginationData}
        />
      )}
      {/* We get empty [] back when using filters and there's no results */}
      {(!formattedData || !formattedData.length) && !isLoading && !error && (
        <div className="py-4 text-gray-400 text-sm">
          No Failure Mode Events found.
        </div>
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
    </Section>
  );
};

export default FailureModeEvents;
