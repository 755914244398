import { ChatPromptTemplate } from "@langchain/core/prompts";
import { StructuredTool } from "@langchain/core/tools";
import { ChatOpenAI, ChatOpenAICallOptions } from "@langchain/openai";

import { createAgentNode, NodeType } from "./utils";

/**
 * The processing is the same for rejecting or clarifying the user's utterance,
 * with the difference being the prompt. For this reason, the same node is used for both, and the
 * prompt is passed in as a parameter.
 * @summary Create and return the node responsible for rejecting or clarifying the user's utterance.
 * @param llmAgent The LLM agent that determines the rejection or clarification response
 * @param tools The tools available to the LLM
 * @param prompt The prompt to send to the LLM
 * @returns The node responsible for running the rejecting or clarifying the user's utterance.
 */
const getRejectOrClarifyNode = (
  llmAgent: ChatOpenAI<ChatOpenAICallOptions>,
  tools: StructuredTool[],
  prompt: ChatPromptTemplate
): NodeType => {
  return createAgentNode(llmAgent, tools, prompt, {
    tool_choice: { type: "function", function: { name: "respondToUser" } },
  });
};

export default getRejectOrClarifyNode;
